import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AlertPopup from "../components/AlertPopup";
import InputFields from "../components/FormComponents/InputFields";
import currentTime from "../hooks/currentTime";

export default function MasterLogin() {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [buttonIcon, setButtonIcon] = useState("fa fa-lock");
  const history = useNavigate();
  const api = process.env.REACT_APP_API_LINK;

  async function MasterLoginSubmit(e) {
    e.preventDefault();
    setButtonIcon("fas fa-circle-notch animate-spin");

    let formData = new FormData();
    formData.append("user_email", email);
    formData.append("user_pass", password);

    await fetch(`${api}login`, {
      method: "POST",
      body: formData,
    })
      .then(async function (response) {
        // first then()
        if (response.ok) {
          const obj = await response.json();
          if (obj?.token) {
            document.cookie = `access_token=${obj.token};path=/;`;
            document.cookie = `access_master=${obj.token};path=/;`;
          }

          setSuccess("Successfully Logged In!");
          setButtonIcon("fa fa-lock");

          history("/master/dashboard");
        } else if (response.error) {
          throw new Error(response.error);
        } else {
          throw new Error("Something Went Wrong. Please Try Again!");
        }
      })
      .catch((err) => {
        setError("Something Went Wrong! Try Again" + currentTime());
        setButtonIcon("fa fa-lock");
      });
  }
  return (
    <>
      <AlertPopup textError={error} textSuccess={success} />
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div className="text-center">
            <i className="fas fa-user-shield text-5xl"></i>

            <h2 className="mt-6 text-center text-3xl font-normal text-gray-900">
              Master Login Area
            </h2>
          </div>
          <form
            className="mt-8 space-y-6 text-left"
            onSubmit={(e) => MasterLoginSubmit(e)}
          >
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <InputFields
                  title="Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  id="emailAddress"
                  placeHolder="Enter Your E-mail"
                />
              </div>
              <div>
                <InputFields
                  title="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  id="password"
                  placeholder="Enter Your Password"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-[#111827] focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div>

              <div className="text-sm"></div>
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="group w-full flex justify-center mb-6 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#111827] hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="pr-2">
                  <i className={buttonIcon}></i>
                </span>
                Sign in
              </button>
              <Link to="/" className="text-indigo-700">
                Go To Home
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
