import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import getCookie from "../../../hooks/getCookie";
import ImageUpload from "../../../components/FormComponents/ImageUpload";
import { toast } from "react-hot-toast";

export default function QuestionAdd() {

  const form = useRef(null);
  const textarea = useRef(null);

  const { id } = useParams();

  const [close_btn, setCloseBtn] = useState(false);
  const [divHidden, setDivHidden] = useState(false);

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const api = process.env.REACT_APP_API_LINK;
  const token = getCookie("access_token");

  const [quesName, setQuesName] = useState(null);
  const [quesA, setQuesA] = useState(null);
  const [quesB, setQuesB] = useState(null);
  const [quesC, setQuesC] = useState(null);
  const [quesD, setQuesD] = useState(null);
  const [quesDescribes, setQuesDescribes] = useState(null);
  const [quesCorrectAns, setQuesCorrectAns] = useState(null);
  const [quesConfirm, setQuesConfirm] = useState(false);
  const [ques_img, setQuesImg] = useState(false);

  const submitQues = async (e) => {
    e.preventDefault();
    setSubmitLoading(true)
    setLoading(true);

    if (
      quesName &&
      quesA &&
      quesB &&
      quesC &&
      quesD &&
      quesCorrectAns &&
      quesConfirm
    ) {
      const options = {
        A: quesA,
        B: quesB,
        C: quesC,
        D: quesD,
      };

      const formData = new FormData();
      formData.append("name", quesName);
      formData.append("options", JSON.stringify(options));
      formData.append("correct", quesCorrectAns);
      formData.append("ques_img", ques_img);

      if (quesDescribes) {
        formData.append("describes", quesDescribes);
      }

      await fetch(`${api}question-add`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
        .then(async (res) => {
          if (res.ok) {
            const getData = await res.json();
            setDivHidden(true);
            setCloseBtn(false);
            setLoading(false);
            setSuccess(getData.success);
            setSubmitLoading(false)
            toast.success(`${getData.success}`)
          } else {
            throw new Error("Something Went Wrong");
          }
        })
        .catch((err) => {
          // setError("" + currentTime());
          toast.error('Something Went Wrong')
          setLoading(false);
          setSubmitLoading(false)

        });
    } else {
      // setError(`! ${currentTime()}`);
      toast.error('Important Fields Are Missing')
      setLoading(false);
      setSubmitLoading(false)

    }
  };

  function newQues(e) {
    setCloseBtn(true);
    setDivHidden(false);
    // setError(false);
    setLoading(false);
    setSubmitLoading(false);
    setQuesName(null);
    setQuesA(null);
    setQuesB(null);
    setQuesC(null);
    setQuesD(null);
    setQuesDescribes(null);
    setQuesCorrectAns(null);
    setQuesConfirm(false);
    form.current.reset();
    textarea.current.value = "";
    // forceUpdate();
  }
  return (
    <div className="py-8">
      {/* <AlertPopup textError={error} /> */}

      <div
        className={`fixed top-[0%] z-10 right-[0%] transition bg-black h-[100%] w-[100%] opacity-70 ${close_btn || !divHidden ? `hidden` : null
          }`}
      ></div>
      <div
        className={`absolute bg-gray-100 z-20 p-10 shadow-xl rounded-lg top-[35%] right-[35%] ${close_btn || !divHidden ? `hidden` : null
          }`}
      >
        <i
          className="fa-solid fa-close cursor-pointer"
          onClick={() => setCloseBtn(true)}
        ></i>

        <h2 className="my-4 text-2xl">{success}</h2>

        <button
          className="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-[#111827] rounded-lg shadow-md hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200"
          onClick={(e) => newQues(e)}
        >
          Add Another Question
        </button>
      </div>

      <div className="mb-1 mx-auto justify-between shadow rounded bg-white p-4 w-100 lg:w-[70%]">
        <form
          ref={form}
          className="mt-8 space-y-6 text-left"
          onSubmit={(e) => submitQues(e)}
        >
          <div className="">
            <h2 className="text-black font-bold text-2xl break-words">
              Question Title: {quesName}
            </h2>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className="mt-4">
                <label htmlFor="ques-name" className="relative">
                  Question Title
                  
                </label>

                <textarea
                  ref={textarea}
                  id="ques-name"
                  placeholder="Question Title"
                  className="mt-2 mb-4 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  defaultValue={quesName}
                  onChange={(e) => setQuesName(e.target.value)}
                ></textarea>
              </div>

              <div className="my-4 p-2 border-2">
                <h2 className="relative">
                  Options
                  
                </h2>
                <div className="mt-4">
                  <div className="flex items-center">
                    <span className="px-4 py-2 bg-gray-400 rounded-bl-md rounded-none">
                      A
                    </span>
                    <input
                      type="text"
                      className="rounded-none w-full px-4 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-br-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      defaultValue={quesA}
                      onChange={(e) => setQuesA(e.target.value)}
                    />
                  </div>

                  <div className="flex items-center mt-4">
                    <span className="px-4 py-2 bg-gray-400 rounded-bl-md rounded-none">
                      B
                    </span>
                    <input
                      type="text"
                      className="rounded-none w-full px-4 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-br-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      defaultValue={quesB}
                      onChange={(e) => setQuesB(e.target.value)}
                    />
                  </div>

                  <div className="flex items-center mt-4">
                    <span className="px-4 py-2 bg-gray-400 rounded-bl-md rounded-none">
                      C
                    </span>
                    <input
                      type="text"
                      className="rounded-none w-full px-4 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-br-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      defaultValue={quesC}
                      onChange={(e) => setQuesC(e.target.value)}
                    />
                  </div>

                  <div className="flex items-center mt-4">
                    <span className="px-4 py-2 bg-gray-400 rounded-bl-md rounded-none">
                      D
                    </span>
                    <input
                      type="text"
                      className="rounded-none w-full px-4 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-br-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      defaultValue={quesD}
                      onChange={(e) => setQuesD(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <label htmlFor="ques-name" className="relative">
                  Correct Answer
                  
                </label>

                <select
                  defaultValue={quesCorrectAns}
                  onChange={(e) => {
                    setQuesCorrectAns(e.target.value);
                  }}
                  className="rounded-none w-full px-4 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                >
                  <option value="A">{quesA}</option>
                  <option value="B">{quesB}</option>
                  <option value="C">{quesC}</option>
                  <option value="D">{quesD}</option>
                </select>
              </div>

              <div className="mt-4">
                <label htmlFor="ques-describes" className="relative">
                  Question Describes (if)
                </label>

                <textarea
                  id="ques-describes"
                  placeholder="Question Describes"
                  className="mt-2 mb-4 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  defaultValue={quesDescribes}
                  onChange={(e) => setQuesDescribes(e.target.value)}
                ></textarea>
              </div>

              <div className="text-black font-bold text-2xl break-words mb-6">
                <label className="text-sm">
                  Add Image with question (if neccessary)
                </label>
                <ImageUpload
                  id="ques_img"
                  type="file"
                  NoImage={true}
                  imageClass="h-60 w-full"
                  url={ques_img ? ques_img : "no_image.png"}
                  onChange={(e) => setQuesImg(e.target.files[0])}
                />
              </div>

              <div className="mt-4">
                <div className="flex items-center gap-2">
                  <input
                    onClick={() =>
                      quesConfirm ? setQuesConfirm(false) : setQuesConfirm(true)
                    }
                    type="checkbox"
                  />
                  <span>I have checked everything</span>
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            disabled={submitLoading ? "disabled" : null}
            className="group flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#111827] hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span className="pr-2">
              {!submitLoading || !loading ? (
                <i className="fa fa-check"></i>
              ) : (
                <i className="fas fa-circle-notch animate-spin"></i>
              )}
            </span>
            {id ? "Save" : "Publish"}
          </button>
        </form>
      </div>
    </div>
  );
}
