import { useEffect, useRef, useState } from "react";
import InputFields from "../FormComponents/InputFields";
import AlertPopup from "../AlertPopup";
import QuizBeforeBegin from "./QuizBeforeBegin";
import { useParams } from "react-router-dom";
import currentTime from "../../hooks/currentTime";

export default function VerifyQuiz() {
  const { id } = useParams();
  const [pinCode, setPinCode] = useState(null);
  const [pinSuccess, setPinSuccess] = useState(false);
  const [close_btn, setCloseBtn] = useState(false);
  const [divHidden, setDivHidden] = useState(true);

  const [error, setError] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const api = process.env.REACT_APP_API_LINK;

  useEffect(() => {
    setCloseBtn(false);
    setDivHidden(true);

    // setTimeout(() => {
    //     setDivHidden(false);
    // }, 5000);
  }, []);

  function pinCodeSubmit(e) {
    e.preventDefault();
    setLoading(true);

    if (id) {
      async function pinVerify() {
        const formData = new FormData();
        formData.append("pin", pinCode);
        await fetch(`${api}quiz-verify/${id}`, {
          method: "POST",
          body: formData,
        })
          .then(async (res) => {
            if (res.ok) {
              const getData = await res.json();
              if (getData.success) {
                setSuccess(getData.success);
                setPinSuccess(true);
                setLoading(false);
              } else if (getData.error) {
                setError(getData.error + currentTime());
                setLoading(false);
                setPinSuccess(false);
              } else {
                setLoading(false);
              }
            } else {
              throw new Error("Something Went Wrong!");
            }
          })
          .catch((err) => {
            setError("Something Went Wrong!" + currentTime());
            setLoading(false);
          });
      }
      pinVerify();
    } else {
      setLoading(false);
      setError("Something Went Wrong! Try Again" + currentTime());
    }
  }

  return (
    <>
      {!pinSuccess ? (
        <>
          {error ? (
            <AlertPopup textError={error} />
          ) : success ? (
            <AlertPopup textSuccess={success} />
          ) : null}

          <div
            className={`fixed top-[0%] z-10 right-[0%] transition bg-black h-[100%] w-[100%] opacity-70 ${
              close_btn || !divHidden ? `hidden` : null
            }`} //</>onClick={() => setCloseBtn(true)}
          ></div>
          <div
            className={`absolute bg-gray-100 z-20 p-10 shadow-xl rounded-lg top-[25%] right-[15%] md:top-[30%] md:right-[35%] lg:top-[35%] lg:right-[45%] ${
              close_btn || !divHidden ? `hidden` : null
            }`}
          >
            {/* <i className="fa-solid fa-close cursor-pointer" onClick={() => setCloseBtn(true)}></i> */}

            <h2 className="my-4 text-2xl">Quiz Room PIN CODE</h2>
            <form className="" onSubmit={(e) => pinCodeSubmit(e)}>
              <InputFields
                title="Pin Code"
                type="password"
                placeholder="Pin Code (Blank if no pin)"
                important
                onChange={(e) => setPinCode(e.target.value)}
                disabled={Loading ? "disabled" : null}
              />

              <button
                className="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-[#111827] rounded-lg shadow-md hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200"
                onClick={(e) => pinCodeSubmit(e)}
              >
                <span className="pr-2">
                  {!Loading ? (
                    <i className="fa fa-check"></i>
                  ) : (
                    <i className="fas fa-circle-notch animate-spin"></i>
                  )}
                </span>
                Submit
              </button>
            </form>
          </div>
        </>
      ) : (
        <QuizBeforeBegin pin={pinCode} />
      )}
    </>
  );
}
