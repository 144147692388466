import logoImg from "../assets/img/logo.png";
import { useState } from "react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
// import getCookie from "../hooks/getCookie";
import { useAuth } from "../hooks/useAuth";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const location = useLocation();

  const [mobileMenu, setMobileMenu] = useState(false);

  const Auth = useAuth();

  function mobileMenuTrigger() {
    if (mobileMenu) {
      setMobileMenu(false);
    } else if (!mobileMenu) {
      setMobileMenu(true);
    }
  }

  const navigation = [
    { name: "Home", href: "/", auth: false },
    { name: "Live Exams", href: "/quiz-live", auth: false },
    // { name: "Jobs", href: "/jobs", auth: false },
  ];

  // const history = useNavigate();
  // function signOut() {
  //   document.cookie = "access_token=; Max-Age=0";
  //   if (getCookie("ccd_master")) {
  //     document.cookie = "ccd_master=; Max-Age=0";
  //   }
  //   history("/login");
  // }

  return (
    <header as="nav" className="bg-gray-800 border-[#B7714B] shadow-xl">
      <>
        {/* ======== TOP BAR ======== */}

        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-20">
            {/* LOGO FOR MOBILE */}
            <div className="flex flex items-center sm:items-stretch sm:justify-start">
              <Link to="/" className="flex items-center">
                <img
                  className="block lg:hidden h-[1.3rem] lg:h-[1.4rem]"
                  src={logoImg}
                  alt="CodeCareBD"
                />
                <img
                  className="hidden lg:block h-[1.3rem] lg:h-[1.4rem]"
                  src={logoImg}
                  alt="CodeCareBD"
                />
                {/* <h4 className='text-[#111827] ml-4'>CodeCareBD</h4> */}
              </Link>
            </div>

            <div className="">
              <div className="hidden lg:block">
                <div className="flex space-x-4">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.href === location.pathname
                          ? "bg-[#111827] text-white"
                          : "text-gray-300 hover:bg-[#111827] hover:text-white",
                        "px-3 py-2 rounded-md text-xxl font-medium"
                      )}
                    >
                      {item.name}
                    </Link>
                  ))}

                  {/* {!Auth ? (
                    <>
                      <Link
                        key={20}
                        to="/login"
                        className={classNames(
                          "/login" === location.pathname
                            ? "bg-[#111827] text-white"
                            : "text-gray-300 hover:bg-[#111827] hover:text-white",
                          "px-3 py-2 rounded-md text-xxl font-medium"
                        )}
                      >
                        Login
                      </Link>
                      <Link
                        key={21}
                        to="/register"
                        className={classNames(
                          "/register" === location.pathname
                            ? "bg-[#111827] text-white"
                            : "text-gray-300 hover:bg-[#111827] hover:text-white",
                          "px-3 py-2 rounded-md text-xxl font-medium"
                        )}
                      >
                        Register
                      </Link>
                    </>
                  ) : (
                    <Link
                      key={20}
                      to="/profile"
                      className={classNames(
                        "/profile" === location.pathname
                          ? "bg-[#111827] text-white"
                          : "text-gray-300 hover:bg-[#111827] hover:text-white",
                        "px-3 py-2 rounded-md text-xxl font-medium"
                      )}
                    >
                      Your Profile
                    </Link>
                  )}

                  <a
                    href="https://play.google.com/store/apps/details?id=com.perlitest.apppreli"
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-300 hover:bg-[#111827] hover:text-white px-3 py-2 rounded-md text-xxl font-medium"
                  >
                    Download Our App
                  </a> */}
                </div>
              </div>
            </div>

            {/* Mobile menu button*/}
            <div className="flex items-center lg:hidden">
              <button
                className="inline-flex items-center justify-center p-2 rounded-md text-white bg-[#111827] hover:text-white hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                onClick={(e) => mobileMenuTrigger(e)}
              >
                <span className="sr-only">Open main menu</span>
                <i className="fa-solid fa-bars rounded p-2"></i>
              </button>
            </div>
          </div>
        </div>

        {/* MOBILE MENU HEADER */}
        <div className={mobileMenu ? "lg:hidden" : "hidden"}>
          <div className="px-2 pt-2 pb-3 space-y-1">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={classNames(
                  item.href === location.pathname
                    ? "text-white bg-[#111827] hover:bg-[#111827] hover:text-white block px-3 py-2 rounded-md text-xxl font-medium"
                    : "block px-3 py-2 text-white"
                )}
                aria-current={item.current ? "page" : undefined}
              >
                {item.name}
              </Link>
            ))}
            {/* {!Auth ? (
              <>
                <Link
                  key={20}
                  to="/login"
                  className={classNames(
                    "/login" === location.pathname
                      ? "bg-[#111827] text-white hover:bg-[#111827] hover:text-white block px-3 py-2 rounded-md text-xxl font-medium"
                      : "block px-3 py-2 text-white"
                  )}
                >
                  Login
                </Link>
                <Link
                  key={21}
                  to="/register"
                  className={classNames(
                    "/register" === location.pathname
                      ? "bg-[#111827] text-white hover:bg-[#111827] hover:text-white block px-3 py-2 rounded-md text-xxl font-medium"
                      : "block px-3 py-2 text-white"
                  )}
                >
                  Register
                </Link>
              </>
            ) : (
              <Link
                key={20}
                to="/profile"
                className={classNames(
                  "/profile" === location.pathname
                    ? "text-white bg-[#111827] hover:bg-[#111827] hover:text-white block px-3 py-2 rounded-md text-xxl font-medium"
                    : "block px-3 py-2 text-white"
                )}
              >
                Your Profile
              </Link>
            )}
            <a
              href="https://play.google.com/store/apps/details?id=com.perlitest.apppreli"
              target="_blank"
              rel="noreferrer"
              className="text-white bg-white hover:bg-[#111827] hover:text-white block px-3 py-2 rounded-md text-xxl font-medium"
            >
              Download Our App
            </a> */}
          </div>
        </div>
      </>
    </header>
  );
}
