import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import getCookie from "./getCookie";

function useAuth() {
  const token = getCookie("access_token");
  if (token) {
    return true;
  } else if (CheckMaster()) {
    return true;
  } else {
    return false;
  }
}

function AuthUser(dependencies = null) {
  const UserToken = User_token();
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [UserData, setUserData] = useState([]);
  const [userMeta, setUserMeta] = useState([]);
  const [verified, setVerified] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    AuthUserChild();
  }, []);

  async function AuthUserChild() {
    if (UserToken && UserToken != "") {
      setLoading(true);
      const userData = await fetch("http://127.0.0.1:8000/api/user", {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${UserToken}`,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            const getTheData = await response.json();
            if (getTheData != 0) {
              setUserData(getTheData);
              setUserMeta(JSON.parse(`${getTheData.user_meta}`));
              setLoading(false);
              setVerified(true);
            } else {
              setLoading(false);
              setVerified(false);
            }
          } else {
            throw new Error("No Data Found! Please Reload Again !");
            setLoading(false);
            setError(error);
          }
        })
        .catch((error) => {
          setLoading(false);
          setError(error);
        });
    } else {
      // history('/login');
    }
  }

  return { UserData, userMeta, loading, error, verified };
}

function CheckMaster() {
  const token = getCookie("access_master");
  if (token) {
    return true;
  } else {
    return false;
  }
}
function MasterAuth() {
  const UserToken = getCookie("access_master");
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [UserData, setUserData] = useState([]);
  const [userMeta, setUserMeta] = useState([]);

  useEffect(() => {
    MasterAuthParent();
  }, []);

  async function MasterAuthParent() {
    if (UserToken && UserToken != "") {
      setLoading(true);
      const userData = await fetch("http://127.0.0.1:8000/api/ccd_master", {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${UserToken}`,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            const getTheData = await response.json();
            console.log(getTheData);
            setUserData(getTheData);
            setUserMeta(JSON.parse(`${getTheData.user_meta}`));
            setLoading(false);
          } else {
            console.log(response);
            throw new Error("No Data Found! Please Reload Again !");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else {
    }
  }

  return { UserData, loading };
}

function User_token() {
  if (getCookie("access_token")) {
    return getCookie("access_token");
  } else if (getCookie("access_master")) {
    return getCookie("access_master");
  }
  return false;
}

export { useAuth, AuthUser, MasterAuth, CheckMaster, User_token };
