import { useEffect } from "react";

export default function AboutUs() {
  useEffect(() => {
    document.body.scrollTop =0
    document.documentElement.scrollTop =0
  }, []);
  return (
    <section className="bg-gray-200 px-2 py-5 lg:px-8 lg:block">
      <div className="mt-8 max-w-7xl mx-auto p-6 text-center">
        <h2 className="text-4xl text-center mb-6">আমাদের সম্পর্কে</h2>
        {/* <div className="flex justify-center items-center">
          <img
            src="https://scontent.fcgp31-1.fna.fbcdn.net/v/t39.30808-6/305264987_455994209881406_3157572506674803867_n.png?_nc_cat=107&ccb=1-7&_nc_sid=09cbfe&_nc_eui2=AeHm6vdGoM8ueddFukNnWqflyU64Y_ejf4jJTrhj96N_iAXdoQNcARuuFaxZRsi6sj-5PQIOfmPWFf83QLpfnz5d&_nc_ohc=ybh1TvNKorcAX_cQSmL&_nc_ht=scontent.fcgp31-1.fna&oh=00_AfBNQAAocnAMqejbr_gx45ImyL_ZUJbSxjy3yVHtXhIrQw&oe=63719580"
            alt="CodeCareBD"
            height={150}
            width={150}
          />
        </div> */}
        <p className="my-4">
          তথ্য প্রযুকতি আমাদের দৈনন্দিন জীবনে গুরত্বপূর্ণ অবদান রাখছে , কিন্তু
          শিক্ষা ক্ষেত্রে এখনও গতানুগতিক ধারাটি রয়ে গেছে ।{" "}
          <b className="text-indigo-700">CodeCareBD</b> মনে করে এই অনলাইন যুগে
          ঘরে বসে একজন জব প্রার্থী অনলাইনে প্রস্তুতি নিয়ে পরীক্ষায় অংশগ্রহণ করতে
          পারবে এবং শিক্ষা ক্ষেত্রে প্রযুক্তির বিপ্লব ঘটাতে পারবে এখোনি তার
          উপযুক্ত সময় ।
        </p>

        <p className="my-4">
          <b className="text-indigo-700">CodeCareBD</b> এন্ড্রয়েড অ্যাপের মূল
          লক্ষ্য ইন্টারনেটকে কাজে লাগিয়ে দেশজুড়ে সবার কাছে মান্সম্মত শিক্ষা
          পৌঁছে দেওয়া । একজন জব প্রার্থী বিনামূল্যে স্টাডি মেটারিয়াল সংগ্রহ করে
          ,সেটি থেকে প্রস্তুতি নিয়ে একাধিক প্রার্থীর সাথে পরীক্ষায় অংশগ্রহণ করে
          নিজের স্থান সম্পর্কে নিশ্চিত হতে পারে ।
        </p>

        <p className="my-4">
          <b className="text-indigo-700">CodeCareBD</b> এন্ড্রয়েড অ্যাপটি
          CodecareBD এর একটি পণ্য । এই অ্যাপটি ব্যাংক জব প্রস্তুতির সবচেয়ে বড়
          ডিজিটাল প্ল্যাটফর্ম । এই অ্যাপের মাধ্যমে হাজারো ব্যাংক জব প্রার্থী
          প্রতিযোগীতামূলক পরীক্ষায় অংশগ্রহণের জন্য নিজেকে প্রস্তুত করতে পারে ।
        </p>

        <p className="my-4">
          <b className="text-indigo-700">CodeCareBD</b> গনপ্রজাতন্ত্রী সরকার
          কর্তৃক নিবন্ধ্নকৃত এবং কপিরাইট আইন দ্বারা সুরক্ষিত । প্রস্তুতির জন্য
          কিছু স্টাডি মেটারিয়াল পিডিএফ দেওয়া হবে । এই পিডিএফ কপি করলে অথবা
          ব্যাবসায়িক উদ্দেশ্যে ব্যবহার করলে তার বিরুদ্ধে আইনানুগ ব্যবস্থা নেওয়া
          হবে ।
        </p>
      </div>
    </section>
  );
}
