import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import QuizBox from "../components/QuizBox";
import QuizSearch from "../components/QuizSearch";
import currentTime from "../hooks/currentTime";

export default function PrivateHome() {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [quizData, setQuizData] = useState(false);
    const [limit, setLimit] = useState(10);
    const [hasMore, setHasMore] = useState(true);
    const api = process.env.REACT_APP_API_LINK;

    useEffect(() => {
        async function quizList() {
            if (!quizData) {
                setLoading(true);
            }
            await fetch(`${api}quiz-all-public/${limit}`, {
                method: "GET",
            }).then(async (res) => {
                if (res.ok) {
                    const getData = await res.json();
                    if (getData.success) {
                        if (quizData?.length === getData?.quiz?.length) {
                            setHasMore(false)
                        }
                        setQuizData(prev => getData?.quiz);
                        setLoading(false);
                    } else {
                        setLoading(false)
                    }

                } else {

                    throw new Error('Something Went Wrong!');

                }
            }).catch((err) => {
                setError('Something Went Wrong!' + currentTime);
                setLoading(false);
            })
        }
        quizList();
    }, [api, limit]);


    return (
        <section className='bg-gray-200 px-2 py-5 lg:px-8 lg:block'>

            <QuizSearch />

            <div className="mt-8 max-w-7xl mx-auto p-6">
                <h2 className="my-4 text-2xl font-bold text-center lg:text-start">Special Exams</h2>
                {!loading && quizData ?


                    <InfiniteScroll

                        dataLength={quizData?.length ? quizData?.length : 0}
                        next={() => setLimit(prev => prev + 10)}
                        className="" //To put endMessage and loader to the top.
                        hasMore={hasMore}
                        loader={<h2 className="text-center text-2xl text-bold">Loading ... </h2>}

                    >
                        <div className="p-6 text-white flex justify-center md:justify-start items-center">
                            <Link to="quiz-live" className="px-16 py-2 font-semibold text-white bg-purple-500 shadow-md hover:bg-[#111827] relative rounded-xl">
                                Live Exams
                                <i className="fa-solid fa-circle text-[5px] absolute animate-ping"></i>
                                <i className="fa-solid fa-circle text-[5px] absolute"></i>
                            </Link>
                        </div>

                        <div className="grid grid-cols-1 lg:grid-cols-2 p-6 gap-6">
                            {quizData ?

                                quizData.map((data, key) => (
                                    <QuizBox key={key} quizID={data.id} quizTitle={data.name} quizDate={data.start_date} quizTime={data.start_time} quizTotalParticipate={data.total_participates ? JSON.parse(data.total_participates).length : null} quizStatus={data.status} />
                                ))


                                :

                                <h2 className="text-2xl ">No Result To Show</h2>

                            }
                        </div>
                    </InfiniteScroll>
                    :
                    <span className="animate-spin text-6xl my-6 fa-solid fa-spinner"></span>}
            </div>
        </section>
    );
}