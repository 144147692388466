import { useEffect, useState } from "react";
import QuizBox from "../components/QuizBox";
import currentTime from "../hooks/currentTime";

export default function LiveExams() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [quizData, setQuizData] = useState(false);
    const api = process.env.REACT_APP_API_LINK;

    useEffect(() => {

        async function quizList() {
            setLoading(true);
            const formData = new FormData();
            const quizPinVerify = await fetch(`${api}quiz-all-public`, {
                method: "GET",
            }).then(async (res) => {
                if (res.ok) {
                    const getData = await res.json();
                    if (getData.success) {
                        setQuizData(getData.quiz);
                        setLoading(false);
                    } else {
                        setLoading(false)
                    }

                } else {
                    throw new Error('Something Went Wrong!');
                    setLoading(false)
                }
            }).catch((err) => {
                setError('Something Went Wrong!' + currentTime());
                setLoading(false);
            })
        }
        quizList();
    }, []);

    return (
        <section className='bg-gray-200 px-2 py-5 lg:px-8 lg:block'>

            <div className="max-w-7xl mx-auto mt-8 p-6">
                <h2 className="my-4 text-2xl font-bold">Live Exams</h2>
                {!loading ?

                    <div className="grid grid-cols-1 lg:grid-cols-2 p-6 gap-6">
                        {quizData ?
                            quizData.map((data, key) => (

                                // eslint-disable-next-line eqeqeq
                                data.status == 'publish' ?

                                    <QuizBox key={key} quizID={data.id} quizTitle={data.name} quizDate={data.start_date} quizTime={data.start_time} quizTotalParticipate={data.total_participates ? JSON.parse(data.total_participates).length : null} quizStatus={data.status} />

                                    :

                                    null



                            ))

                            :

                            <h2 className="text-2xl ">No Result To Show</h2>

                        }
                    </div>
                    :
                    <span className="animate-spin text-6xl my-6 fa-solid fa-spinner"></span>}
            </div>
        </section>
    )
}