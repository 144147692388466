import { useEffect } from "react";
import VerifyQuiz from "../components/QuizRoom/VerifyQuiz";

import classes from "../assets/css/QuizRoom.module.css";

export default function QuizRoom() {
  //Right Click And Inspect Disabled
  useEffect(() => {
    //Alert If user try to reload
    window.addEventListener("beforeunload", function (e) {
      var confirmationMessage =
        "It looks like you have been editing something. ";

      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    });

    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
    document.onkeydown = function (e) {
      if (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "C".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "J".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == "U".charCodeAt(0)) {
        return false;
      }
    };
  }, []);

  return (
    <>
      {/* QUIZ ROOM */}
      <section
        className={`max-w-7xl mx-auto px-2 py-5 lg:px-8 lg:block ${classes.preventSelect}`}
      >
        <h1 className="text-3xl font-bold text-center">Quiz Room</h1>
        <p className="text-center">Completely Secure Exam</p>

        <VerifyQuiz />
      </section>
    </>
  );
}
