import { React, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth, User_token } from "../hooks/useAuth";
import PrivateHome from "./PrivateHome";
import PublicHome from "./PublicHome";

export default function Home() {

    const history = useNavigate();
    const token = User_token();
    const Auth = useAuth();

    const api = process.env.REACT_APP_API_LINK;
    useEffect(() => {
        if (Auth) {

            async function Auth() {
                try {
                    const apis = await fetch(`${api}user-details`, {
                        method: "GET",
                        headers: {
                            "Accept": "application/json",
                            "Authorization": `Bearer ${token}`
                        }
                    })
                    if (apis.ok) {
                        // setChecks(true)
                    } else {
                        history('/login');
                        document.cookie = 'access_token=; Max-Age=0'
                    }
                } catch (err) {

                }
            }

            Auth();
        }
    });
    
    return Auth ? <PrivateHome /> : <PublicHome />;
}