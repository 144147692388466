import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import getCookie from "../hooks/getCookie";
import IsJSON from "./IsJSON";

export default function HomeContent() {

    const [notice_button, setNoticeBtn] = useState(false);
    const [notice, setNotice] = useState(false);
    const [loading, setLoading] = useState(true)


    const api = process.env.REACT_APP_API_LINK;
    const token = getCookie("access_token");

    const settingsApi = useCallback(async () => {
        await fetch(`${api}frontend`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        }).then(async (res) => {
            if (res.ok) {
                const getData = await res.json();

                if (getData?.success?.frontend) {
                    setNoticeBtn(getData?.success?.frontend?.notice_button)

                    if (getData?.success?.frontend?.notice) {

                        setNotice(getData?.success?.frontend?.notice)

                    }
                }
                setLoading(false);
            } else {
                throw new Error("Something Went Wrong");
            }
        })
            .catch((err) => {
                setLoading(false);
            });
    }, [api, token])

    useEffect(() => {
        settingsApi();
    }, [settingsApi]);
    return (
        <>
            {
                !loading && notice ?
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: notice }}></div>
                        {
                            notice_button &&
                            <a href={`${IsJSON(notice_button) ? JSON.parse(notice_button)?.buttonLink : '#'}`} className="flex w-40 text-center text-xl mt-6 py-2 px-4 border border-transparent font-medium rounded-md text-white bg-gray-700 hover:bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-xxl" rel="noreferrer" target="_blank" >{
                                IsJSON(notice_button) ? JSON.parse(notice_button)?.buttonTitle : ''}
                            </a>
                        }
                    </div> : loading ?
                        <div className="text-center">

                            <div className="animate-pulse flex space-x-4">
                                <div className="flex-1 space-y-4 py-1">
                                    <div className="h-4 bg-gray-300 rounded w-3/4"></div>
                                    <div className="h-4 bg-gray-300 rounded w-3/4"></div>
                                    <div className="h-4 bg-gray-300 rounded w-3/4"></div>
                                    <div className="space-y-2">
                                        <div className="h-4 bg-gray-300 rounded"></div>
                                        <div className="h-4 bg-gray-300 rounded w-5/6"></div>
                                    </div>

                                    <div className="h-4 bg-gray-300 rounded w-3/4"></div>

                                    <div className="h-4 bg-gray-300 rounded w-5/6"></div>

                                    <div className="h-12 bg-gray-300 rounded w-3/6"></div>
                                </div>


                            </div>
                        </div> :
                        !notice && !notice_button &&
                        <>
                            <h2 className="text-4xl font-bold">CodeCareBD</h2>
                            <p className="">Bank Job Preparation Platform</p>
                            <div className="mt-5 text-6 font-bold leading-8">
                                কোর্স প্ল্যান অনুযায়ী ব্যাংক প্রিলি ও রিটেন প্রস্তুতির জন্য{" "}
                                <a href="tel:01716417634">
                                    <strong>01716417634</strong>
                                </a>{" "}
                                এই নাম্বারে বিকাশ/রকেট/নগদ দিয়ে সেন্ডমানি করে নিচের ফেসবুক লিংকে
                                ইন করে সেন্ড মানির স্কিনশট ইনবক্স করে কনফার্ম করেন। ফেসবুক লিংকঃ{" "}
                                <a
                                    href="https://www.facebook.com/codecarebd01"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <strong>https://www.facebook.com/codecarebd01</strong>
                                </a>
                                .
                            </div>
                            <Link
                                to="/login"
                                className="flex w-40 text-center text-xl mt-6 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#111827] hover:bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadoow-xxl"
                            >
                                Get Started
                            </Link>
                        </>
            }
        </>
    )
}