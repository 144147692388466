import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import QuizImage from '../../assets/img/quizSubmitted.png';
import Countdown from 'react-countdown';

export default function QuizCompleted({ result }) {
    const history = useNavigate();


    const { id } = useParams();

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            // history('/');
        } else {
            // Render a countdown
            return <span>{minutes} Minutes: {seconds} Seconds</span>;
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="max-w-7xl items-center mx-auto my-10 grid grid-cols-1 lg:grid-cols-2 gap-2 text-center">
            <div className="">
                <h2 className='text-3xl mb-10'>Thank you for participate</h2>
                <img src={QuizImage} alt="Quiz" />
            </div>
            <div>
                <div className='my-6 text-center p-4 border-2'>We can hold this page only for <Countdown renderer={renderer} date={Date.now() + 100000} >
                </Countdown>
                </div>
                {result ?

                    <div className='mt-6'>
                        <div className='bg-[#111827] border-t border-gray-400 m-4 my-10 shadow-xl py-4 text-white rounded-xl'>
                            <h4 className='text-center text-xl font-bold'>Your Result and Answer Sheet Below</h4>
                        </div>
                        <div className="bg-[#111827] border-t border-gray-400 m-4 my-10 shadow-xl py-6">

                            <div className="py-4 text-white text-center">
                                <p className="text-xl">Mr/Mrs. {result.name}</p>
                            </div>

                            <div className="px-4 py-4 text-white text-center">
                                <p className="text-xl">Model Test ID: {id}</p>
                            </div>

                            <div className="p-10 text-white text-center">
                                <div className='bg-indigo-500 p-5'>

                                    <div className='sl-no'>
                                        <h4 className='text-xl'>Serial No:</h4>
                                        <p className='text-xl'>{(result !== [] && result) ? result.merit_position : null}</p>
                                    </div>

                                    <div className='flex gap-4 justify-center py-4'>
                                        <div className='crt-answers bg-orange-600 rounded-lg p-2'>
                                            Correct: {result.correct ? Object.keys(JSON.parse(result.correct)).length : null}
                                        </div>
                                        <div className='final-answers bg-orange-600 rounded-lg p-2'>
                                            Final Marks: {result.final_score ? result.final_score : 0}
                                        </div>
                                    </div>

                                    <div>www.codecarebd.com</div>

                                    <div className='today-date-time py-5'>
                                        {new Date().toLocaleDateString()} {new Date().toLocaleTimeString()}
                                    </div>

                                </div>

                            </div>

                            <div className="py-4 text-white text-center w-full px-2">
                                <div className='flex gap-4 justify-center py-4'>
                                    <div className='crt-answers bg-orange-600 rounded-lg py-4 px-2 break-words'>
                                        Correct: {result.correct ? Object.keys(JSON.parse(result.correct)).length : null}
                                    </div>
                                    <div className='wrong-answers bg-orange-600 rounded-lg py-4 px-2 break-words'>
                                        Wrong Marks: {result.wrong ? Object.keys(JSON.parse(result.wrong)).length : null}
                                    </div>
                                    <div className='final-answers bg-orange-600 rounded-lg py-4 px-2 break-words'>
                                        Final Marks: {result.final_score ? result.final_score : 0}
                                    </div>
                                </div>
                            </div>

                            {/* <dl className='rounded-lg'>
                                <div className="bg-[#111827] px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-lg font-bold text-white">
                                        Name:
                                    </dt>
                                    <dd className="mt-1 text-lg text-white sm:mt-0 sm:col-span-2">
                                        Mr/Mrs. {result.name}
                                    </dd>
                                </div>
                                <div className="bg-[#111827] px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-lg font-bold text-white">
                                        Serial No:
                                    </dt>
                                    <dd className="mt-1 text-lg text-white sm:mt-0 sm:col-span-2">
                                        {(result != [] && result) ? result.merit_position : null}
                                    </dd>
                                </div>
                                <div className="bg-[#111827] px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-lg font-bold text-white">
                                        Email
                                    </dt>
                                    <dd className="mt-1 text-lg text-white sm:mt-0 sm:col-span-2">
                                        {result.email}
                                    </dd>
                                </div>
                                {result.phone ?
                                    <div className="bg-[#111827] px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-lg font-bold text-white">
                                            Phone
                                        </dt>
                                        <dd className="mt-1 text-lg text-white sm:mt-0 sm:col-span-2">
                                            {result.phone}
                                        </dd>
                                    </div>
                                    :
                                    null
                                }
                                <div className="bg-[#111827] px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-lg font-bold text-white">
                                        Correct
                                    </dt>
                                    <dd className="mt-1 text-lg text-white sm:mt-0 sm:col-span-2">
                                        {result.correct ? Object.keys(JSON.parse(result.correct)).length : null}
                                    </dd>
                                </div>
                                <div className="bg-[#111827] px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-lg font-bold text-white">
                                        Wrong
                                    </dt>
                                    <dd className="mt-1 text-lg text-white sm:mt-0 sm:col-span-2">
                                        {result.wrong ? Object.keys(JSON.parse(result.wrong)).length : null}
                                    </dd>
                                </div>
                                <div className="bg-[#111827] px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-lg font-bold text-white">
                                        Final Score
                                    </dt>
                                    <dd className="mt-1 text-lg text-white sm:mt-0 sm:col-span-2">
                                        {result.final_score ? result.final_score : 0}
                                    </dd>
                                </div>
                            </dl> */}
                        </div>
                    </div> : ''
                    // history('/')
                }
                <Link to="/" className="bg-[#111827] py-2 px-4 text-white font-bold rounded-lg"><i className="fas fa-arrow-alt-circle-left"></i> Go To Home</Link>
            </div>
        </div>
    )
}