import AlertPopup from "../components/AlertPopup";
import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputFields from "../components/FormComponents/InputFields";
import currentTime from "../hooks/currentTime";
import registration_flex from "../assets/img/registration_box.jpg";

export default function Register() {

    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [name, setName] = useState(false);
    const [email, setEmail] = useState(false);
    const [password, setPassword] = useState(false);
    const [buttonIcon, setButtonIcon] = useState('fa fa-lock');
    const history = useNavigate();
    const api = process.env.REACT_APP_API_LINK;

    async function UserRegSubmit(e) {
        e.preventDefault();
        setButtonIcon("fas fa-circle-notch animate-spin");


        if (name && email && password) {
            let formData = new FormData();
            formData.append("user_name", name);
            formData.append("user_email", email);
            formData.append("user_pass", password);

            await fetch(`${api}userReg`, {
                method: "POST",
                body: formData
            }).then(async function (response) {// first then()
                if (response.ok) {
                    const obj = await response.json();

                    if (obj && obj.token) {
                        document.cookie = `access_token=${obj.token};path=/;`;


                        setSuccess('Successfully Logged In!')
                        setButtonIcon('fa fa-lock');

                        history('/');
                    } else if (obj.error) {
                        setButtonIcon('fa fa-lock');
                        setError(`${obj.error} ${currentTime()}`)
                    } else {

                        setError("Something went Wrong!" + currentTime());
                        setButtonIcon('fa fa-lock');

                    }

                } else if (response.error) {
                    setError(`${response.error} ${currentTime()}`)
                    throw new Error(response.error);
                } else {
                    throw new Error('Something Went Wrong. Please Try Again!');
                }
            }).catch((err) => {
                setError('Something Went Wrong! Try Again' + currentTime())
                setButtonIcon('fa fa-lock');
            });
        } else {
            if (!name && name !== '') {
                setError('Name Field Missing!' + currentTime())
                setButtonIcon('fa fa-lock');

                return false;
            }

            if (!email && email !== '') {
                setError('Email Field Missing!' + currentTime())
                setButtonIcon('fa fa-lock');

                return false;
            }

            if (!password && password !== '') {
                setError('Password Field Missing!' + currentTime())
                setButtonIcon('fa fa-lock');

                return false;
            }

        }
    }

    return (
        <>
            <AlertPopup textError={error} textSuccess={success} />
            <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-lg w-full space-y-8">

                    <div>
                        <img src={registration_flex} class="mx-auto" alt="Registration CodeCareBD" width={200} />
                        <h2 className="mt-6 text-center text-3xl font-normal text-gray-900">Registration Area</h2>
                    </div>
                    <form className="mt-8 space-y-6 text-left" onSubmit={(e) => UserRegSubmit(e)}>
                        <div className="rounded-md shadow-sm -space-y-px">
                            <div>
                                <InputFields title="Name" onChange={(e) => setName(e.target.value)} type="text" id="name" placeHolder="Enter Your Name" />
                            </div>
                            <div>
                                <InputFields title="Email Address" onChange={(e) => setEmail(e.target.value)} type="email" id="emailAddress" placeHolder="Enter Your E-mail" />
                            </div>
                            <div>
                                <InputFields title="Password" onChange={(e) => setPassword(e.target.value)} type="password" id="password" placeholder="Enter Your Password" />
                            </div>
                        </div>

                        <div className="text-center">
                            <button
                                type="submit"
                                className="group w-full flex justify-center mb-6 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#111827] hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                <span className="pr-2">
                                    <i className={buttonIcon}></i>
                                </span>
                                Sign up
                            </button>
                            <Link to="/" className="text-indigo-700">
                                Go To Home
                            </Link>
                        </div>
                    </form>


                </div>
            </div>
        </>
    )
}