import { useEffect } from "react";

export default function PrivacyPolicy() {
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <section className="bg-gray-200 px-2 py-5 lg:px-8 lg:block">
      <div className="mt-8 max-w-7xl mx-auto p-6">
        <h1 className="text-4xl text-center">Policies</h1>
        <div>
          <div className="my-4">
            <p className="my-4"> Last update: 05-10-2021</p>
          </div>

          <p className="my-4">Privacy Policy:</p>

          <p className="my-4">
            CodeCareBD, we operate http://www.codecarebd.com and the Android App
            "CodeCareBD". This page will help you to let know about your privacy
            and policy standards. Here you can understand whats our google and
            why we're here to operate the "CodeCareBD". Basically, we collect
            user some info which is help us to justify users indiviually to find
            them on different major places like to exam result, courses, etc.
            Also, We may use third party services such as Google Firebase, and
            Facebook APIs that collect, email and username for identifying you
            and use those information to provide you our services. We won't
            share your information to any bad hand even other users of our
            Site/App will not be to able to know that you're using those
            services. So, you're safe here. Basically, we collect these things :
          </p>

          <ul className="my-4">
            <li>User Name</li>
            <li>User Email</li>
            <li>User Phone Number</li>
            <li>Basic profile info.</li>
          </ul>

          <p className="my-4">
            <b>Contact Us:</b>
          </p>

          <p className="my-4">
            If you have any questions about this Privacy Policy, please contact
            us at :
          </p>

          <p className="my-4">Email: codecarebd@codecarebd.com</p>

          <p className="my-4">Phone: +8801716-417634</p>

          <p className="my-4">© CodeCareBD</p>

          <p className="my-4">Trade License No.: 65994</p>

          <p className="my-4">Registered with kushtia porosova.</p>

          <p className="my-4">Kushtia, Bangladesh.</p>
        </div>
      </div>
    </section>
  );
}
