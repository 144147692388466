import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import getCookie from '../../../hooks/getCookie';

export default function QuestionList() {

    const [loading, setLoading] = useState(false);
    const api = process.env.REACT_APP_API_LINK;
    const token = getCookie('access_token');

    const [quesData, setQuesData] = useState(null);

    const quesApiFunc = useCallback(async () => {
        setLoading(true);
        await fetch(`${api}question-all`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(async (res) => {
            if (res.ok) {
                const getData = await res.json();

                setQuesData(getData);
                setLoading(false);

            } else {
                throw new Error("Something Went Wrong");
            }
        }).catch((err) => {
            // setError('' + currentTime());
            toast.error('Something Went Wrong')
            setLoading(false)
        });
    }, [api, token])


    useEffect(() => {

        quesApiFunc();

    }, [quesApiFunc])


    async function deleteQues(id) {

        if (id) {
            setLoading(true);
            await fetch(`${api}question-delete/${id}`, {
                method: "DELETE",
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(async (res) => {
                if (res.ok) {
                    const getData = await res.json();
                    if (getData) {
                        toast.success('Successfully Deleted')
                    }
                    // setSuccess(`Successfully Deleted ${currentTime()}`)
                    quesApiFunc();
                    // setLoading(false);

                } else {
                    throw new Error("Something Went Wrong");
                }
            }).catch((err) => {
                // setError('' + currentTime());
                toast.error('Something Went Wrong')
                setLoading(false)
            });
        }
    }

    return (
        <div className="">
            {/* <AlertPopup textSuccess={success} textError={error} /> */}

            <div className="py-8">
                <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full">
                    <h2 className="text-2xl text-dark leading-tight">
                        Questions
                    </h2>
                    <div className="text-end">
                        <form className="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center">


                            {/* <div className="relative">
                                <input type="text" id="&quot;form-subscribe-Filter" className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="name" />
                            </div>


                            <button className="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-[#111827] rounded-lg shadow-md hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200">
                                Filter
                            </button> */}

                            <Link to='/master/quiz-manage' className="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-[#111827] rounded-lg shadow-md hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200">
                                Add New Question
                            </Link>

                        </form>
                    </div>
                </div>
                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                        <table className="min-w-full leading-normal">
                            <thead className="hidden lg:contents">
                                <tr>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                        Question
                                    </th>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                        Correct Ans
                                    </th>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                        Quiz ID
                                    </th>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                        Created At
                                    </th>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                {quesData && !loading ?
                                    quesData.map((data, key) => (
                                        <tr key={key}>
                                            <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <div className="block lg:hidden">Question</div>
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {data.name}
                                                </p>
                                            </td>
                                            <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <div className="block lg:hidden">Correct Ans</div>
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {data.correct}
                                                </p>
                                            </td>
                                            <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <div className="block lg:hidden">Quiz ID</div>
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {data.quiz_id ? data.quiz_id : 'Not Assigned Yet'}
                                                </p>
                                            </td>
                                            <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <div className="block lg:hidden">Created At</div>
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {data.created_at ? new Date(data.created_at).toLocaleString('en-US') : null}
                                                </p>
                                            </td>
                                            <td className="flex items-centerlg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm mb-4 lg:mb-0">
                                                <Link to={`/master/question-manage/${data.id}`} className="ml-4 text-white hover:text-white bg-[#111827] p-2 rounded-lg shadow-lg">
                                                    Edit
                                                </Link>
                                                <button onClick={(e) => { deleteQues(data.id) }} className="ml-4 text-white hover:text-white bg-red-700 p-2 rounded-lg shadow-lg">
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    )

                                    )
                                    : loading ?
                                        <tr>
                                            <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm mb-4" colSpan={5}>
                                                <h3 className='text-center font-bold text-xl'>Loading...</h3>
                                            </td>
                                        </tr>
                                        :

                                        null

                                }

                            </tbody>
                        </table>


                        {/* <div className="px-5 bg-white py-5 flex flex-col xs:flex-row items-center xs:justify-between">
                            <div className="flex items-center">
                                <button type="button" className="w-full p-4 border text-base rounded-l-xl text-gray-600 bg-white hover:bg-gray-100">
                                    <svg width="9" fill="currentColor" height="8" className="" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z">
                                        </path>
                                    </svg>
                                </button>
                                <button type="button" className="w-full px-4 py-2 border-t border-b text-base text-indigo-500 bg-white hover:bg-gray-100 ">
                                    1
                                </button>
                                <button type="button" className="w-full px-4 py-2 border text-base text-gray-600 bg-white hover:bg-gray-100">
                                    2
                                </button>
                                <button type="button" className="w-full px-4 py-2 border-t border-b text-base text-gray-600 bg-white hover:bg-gray-100">
                                    3
                                </button>
                                <button type="button" className="w-full px-4 py-2 border text-base text-gray-600 bg-white hover:bg-gray-100">
                                    4
                                </button>
                                <button type="button" className="w-full p-4 border-t border-b border-r text-base  rounded-r-xl text-gray-600 bg-white hover:bg-gray-100">
                                    <svg width="9" fill="currentColor" height="8" className="" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z">
                                        </path>
                                    </svg>
                                </button>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}