import React, { useRef, useState } from "react";
import demo_image from "../../assets/img/logo.png";
import getCookie from '../../hooks/getCookie';
import currentTime from '../../hooks/currentTime';
export default function ImageUploadServer({
    title,
    id,
    placeHolder,
    url,
    circle = null,
    imageAlt,
    className,
    InputChange,
    InputField = false,
    IsImage = true,
    imageH = false,
    imageW = false,
    defaultImg = false,
    ...rest
}) {
    const input = useRef(null);

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fileURL, setFileURL] = useState(false);
    const token = getCookie('access_master');
    const api = process.env.REACT_APP_API_LINK;

    const uploading = async (e) => {
        if (e) {
            setLoading(true)
            let formdata = new FormData();
            formdata.append('file_upload', e.target.files[0]);


            await fetch(`${api}upload-images`, {
                method: "POST",
                body: formdata,
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(async (res) => {
                if (res.ok) {
                    const getData = await res.json();
                    if (getData?.success) {
                        setSuccess(true);
                        setFileURL(getData?.success);
                        InputChange(getData?.success)
                    } else {
                        setError('Something went wrong')
                    }
                    setLoading(false)
                } else {
                    throw new Error("Please Try again! Something Went Wrong");
                }
            }).catch((err) => {
                setError('Please Try again! Something Went Wrong' + currentTime());
                setLoading(false)
            });
        } else {
            setLoading(false);
            setError('Please upload your image correctly')
        }

    }


    return (
        <>
            <label htmlFor={id}>
                {title}
            </label>
            {IsImage ? (
                <img
                    src={
                        url
                            ? url instanceof Object
                                ? URL.createObjectURL(url)
                                : `${process.env.NEXT_PUBLIC_APP_ASSETS_URL}${url}`
                            : defaultImg
                                ? defaultImg
                                : demo_image.src
                    }
                    className={`cursor-pointer ${circle ? `rounded-full h-[50%] w-[50%]` : `h-40 w-60`
                        } ${className}`}
                    onClick={() => input.current.click()}
                    {...rest}
                    alt={imageAlt ? imageAlt : "..."}
                    height={imageH ? imageH : "150"}
                    width={imageW ? imageW : "150"}
                />
            ) : null}
            <div className={` ${loading && 'hidden'} ${InputField ? "" : "hidden"
                }`}>
                <input
                    ref={input}
                    id={id}
                    name={id}
                    type={"file"}
                    className={`${InputField ? "" : "hidden"
                        } rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm text-black`}
                    placeholder={placeHolder}
                    // onChange={InputChange}
                    onChange={uploading}
                />
                <p className="text-black">only supported file type : jpeg,png,bmp</p>
            </div>


            {
                loading &&
                <div className="flex gap-2 my-2 text-black items-center">
                    <p>Uploading. Please wait...</p>
                    <i className="fa-solid fa-spinner animate-spin text-xm"></i>
                </div>
            }
        </>
    );
}
