import { React, useCallback, useEffect, useState } from "react";
import getCookie from "../../../hooks/getCookie";

export default function Dashboard() {
    const [overviewQuiz, setOverviewQuiz] = useState(null);
    const [loading, setLoading] = useState(false);
    const token = getCookie('access_token');

    const api = process.env.REACT_APP_API_LINK;

    const allDataFetch = useCallback(async () => {
        setLoading(true);
        await Promise.all([fetch(`${api}quiz-all`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })]).then(async ([responseQuiz]) => {
            if (responseQuiz.ok) {
                const getDataQuiz = await responseQuiz.json();
                setOverviewQuiz(getDataQuiz);
                setLoading(false)
            } else {
                throw new Error("This is not admin requent, Please Try again!");
            }

        }).catch((error) => {
            setLoading(false);
        });
    }, [api, token])
    useEffect(() => {


        allDataFetch();

    }, [allDataFetch]);

    return (
        <>
            <div>
                <div className="statstic grid grid-cols-1 sm:grid-cols-2 mt-10 gap-4">
                    <div className="students-statstic text-white mt-10 p-4 bg-slate-600">
                        <h2 className="text-4xl">Overview</h2>
                        <table className="min-w-full leading-normal bg-slate-600">
                            <thead className="hidden lg:contents">
                                <tr>
                                    <th scope="col" className="px-5 py-3 bg-slate-600  border-b border-gray-200 text-white  text-left text-sm uppercase font-normal">
                                        Quiz Name
                                    </th>
                                    <th scope="col" className="px-5 py-3 bg-slate-600  border-b border-gray-200 text-white  text-left text-sm uppercase font-normal">
                                        Total Participate
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                {Array.isArray(overviewQuiz) && !loading ?

                                    overviewQuiz.length === 0 ?
                                        <tr>
                                            <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-slate-600 text-sm mb-4" colSpan={2}>
                                                <h3 className='text-center font-bold text-xl'>No Quiz Created Yet!</h3>
                                            </td>
                                        </tr> :

                                        overviewQuiz.map((data, key) => (
                                            <tr key={key}>
                                                <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-slate-600 text-sm">
                                                    <div className="block lg:hidden">Serial</div>
                                                    <p className="text-white whitespace-no-wrap">
                                                        {key + 1}
                                                    </p>
                                                </td>
                                                <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-slate-600 text-sm">
                                                    <div className="block lg:hidden">Model Test Name</div>
                                                    <p className="text-white whitespace-no-wrap">
                                                        {data.name}
                                                    </p>
                                                </td>

                                            </tr>
                                        ))

                                    : loading ?
                                        <tr>
                                            <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-slate-600 text-sm mb-4" colSpan={2}>
                                                <h3 className='text-center font-bold text-xl'>Loading...</h3>
                                            </td>
                                        </tr> : null}


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}