import { React, useEffect, useState } from "react";

export default function AlertPopup({ textError = null, textSuccess = null, ...rest }) {
    const [close_btn, setCloseBtn] = useState(false);
    const [divHidden, setDivHidden] = useState(textError ? textError : textSuccess ? textSuccess : false);

    useEffect(() => {
        setCloseBtn(false);
        // setDivHidden(true);
        setTimeout(() => {
            setDivHidden(false);
        }, 5000);

        if(textSuccess || textError){
            setDivHidden(true);
        }
    }, [textError, textSuccess]);

    return (
        <div className={`fixed top-4 z-10 right-6 transition duration-150 z-[999] ${close_btn || !divHidden ? `hidden` : null}`}>

            <div className={`flex p-4 mb-4 text-lg text-white ${textError ? 'bg-[red]' : textSuccess ? 'bg-[#7E22CE]' : null} rounded-lg relative items-center space-x-2`} role="alert">
                <i className="fa-solid fa-close cursor-pointer" onClick={() => setCloseBtn(true)}></i>
                {
                    textError ?
                    
                        <>
                            <svg className="inline flex-shrink-0 mr-3 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                            <div>
                                <span className="font-medium">Alert!</span> {textError}
                            </div>
                        </>

                        :

                    textSuccess ?

                        <>
                            <i className="fas fa-check-circle"></i>
                            <div>
                                <span className="font-medium">Success!</span> {textSuccess}
                            </div>
                        </>

                        :
                            
                        null
                }
            </div>

        </div>
    );
}