import { Link } from "react-router-dom";
// import contact_footer from "../assets/img/contact_footer.jpg";
export default function Footer() {
  return (
    <footer className="bg-gray-800">
      <div className="max-w-7xl mx-auto px-2 py-20 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-10 items-center text-white">
          <div className="col-span-1 lg:col-span-2 text-center lg:text-left">
            <h3 className="text-2xl font-bold text-white">CodeCareBD</h3>
            <p className="text-sm py-4">
              CodeCareBD বাংলাদেশের কুষ্টিয়ায় অবস্থিত একটি প্রযুক্তি কোম্পানি।
              আমরা ওয়েবসাইট ডিজাইন এবং ডেভেলপমেন্ট মাথায় রেখে শুরু করেছি,
              কিন্তু সময়ের সাথে সাথে, ক্যানভাস একটি পূর্ণ-পরিষেবা সফ্টওয়্যার
              ডেভেলপমেন্ট কোম্পানিতে পরিণত হয়েছে। বাংলাদেশের সেরা ওয়েব ডিজাইন
              এবং ডেভেলপমেন্ট কোম্পানি হিসেবে পরিচিত
            </p>
          </div>
          <div className="col-span-1 text-center lg:text-left">
            <h4 className="text-xl font-bold text-white">Quick Links</h4>
            <ul className="py-4">
              <li className="text-sm py-2">
                <Link to="/privacy-policy" rel="noreferrer">
                  Privacy policy
                </Link>
              </li>
              <li className="text-sm py-2">
                <Link to="/terms">Terms and Conditions</Link>
              </li>
              <li className="text-sm py-2">
                <a
                  href="https://www.facebook.com/groups/codecarebd/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook group
                </a>
              </li>
              <li className="text-sm py-2">
                <a
                  href="https://www.youtube.com/@CodeCareBD"
                  target="_blank"
                  rel="noreferrer"
                >
                  YouTube Channel
                </a>
              </li>
            </ul>
          </div>
          <div className="col-span-1 text-center lg:text-left">
            <div className="hover:shadow-xl rounded-lg flex items-center justify-center lg:justify-start">
              <div className="icon p-4 pr-1">
                <a href="tel:+8801646599398">
                  <i className="fa-solid fa-phone text-sm text-white rounded-full bg-[#111827] p-3"></i>
                </a>
              </div>
              <div>
                <h2 className="py-0 text-sm p-4 font-bold text-white">
                  Contact Info
                </h2>
                <div className="px-4">
                  <a href="tel:+8801646599398">
                    <span className="text-sm">01646599398</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="hover:shadow-xl my-2 rounded-lg flex items-center justify-center lg:justify-start">
              <div className="icon p-4 pr-1">
                <a
                  href="https://www.facebook.com/CodeCareBD"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook-f text-sm text-white rounded-full bg-[#111827] p-3"></i>
                </a>
              </div>
              <div>
                <a
                  href="https://www.facebook.com/CodeCareBD"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h2 className="py-0 text-sm p-4 font-bold text-white font-bold">
                    Facebook Page
                  </h2>
                </a>
                <div className="px-4">
                  <a
                    href="https://www.facebook.com/CodeCareBD"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="text-sm font-bold  ">CodeCareBD</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="hover:shadow-xl my-2 rounded-lg flex items-center justify-center lg:justify-start">
              <div className="icon p-4 pr-1">
                <i className="fa-solid fa-location-dot text-sm text-white rounded-full bg-[#111827] p-3"></i>
              </div>
              <div>
                <h2 className="py-0 text-sm p-4 font-bold text-white">
                  Our Address
                </h2>
                <div className="px-4">
                  <span className="text-sm">Kushtia Sadar, Kushtia</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="" />
      {/* <div className="py-4 text-sm lg:text-lg text-center">
        <div className="flex justify-center items-center">
          <div className="ml-4 text-white hover:text-white bg-[#111827] p-2 shadow-lg">
            <Link to="/about-us">আমাদের সম্পর্কে</Link>
          </div>
          <div className="ml-4 text-white hover:text-white bg-[#111827] p-2 shadow-lg">
            <Link to="/privacy-policy">নীতিসমূহ</Link>
          </div>
        </div>
      </div> */}
      <div className="py-4 text-sm lg:text-lg text-center bg-gray-800">
        <p className="text-white">
          Copyright © {new Date().getFullYear()} - CodeCareBD - All Rights
          Reserved. Created By{" "}
          <a
            className="text-indigo-500"
            href="https://codecarebd.com"
            target="_blank"
            rel="noreferrer"
          >
            CodeCareBD
          </a>
        </p>
      </div>
    </footer>
  );
}
