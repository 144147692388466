import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import getCookie from "../../../hooks/getCookie";
import { toast } from "react-hot-toast";

export default function NoticeManage() {

  const form = useRef(null);
  const textarea = useRef(null);

  const { id } = useParams();


  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const api = process.env.REACT_APP_API_LINK;
  const token = getCookie("access_token");

  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [link, setLink] = useState(null);
  const [linkText, setLinkText] = useState(null);

  const noticeAPIFunc = useCallback(async () => {
    await fetch(`${api}notice-single/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        if (res.ok) {
          const getData = await res.json();

          if (getData?.success?.notice) {
            setName(getData?.success?.notice?.title)
            setDescription(getData?.success?.notice?.description)
            setLink(getData?.success?.notice?.link)
            setLinkText(getData?.success?.notice?.link_text)
          }
          setLoading(false);
          // setSuccess('Successfully Saved');
        } else {
          throw new Error("Something Went Wrong");
        }
      })
      .catch((err) => {
        // setError("");
        toast.error('Something Went Wrong')
        setLoading(false);
      });
  }, [api, token, id])

  useEffect(() => {

    noticeAPIFunc();
  }, [noticeAPIFunc]);

  const submitQues = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    setLoading(true);

    if (
      name
    ) {

      const formData = new FormData();
      formData.append("title", name);
      formData.append("description", description);
      formData.append("link", link);
      formData.append("link_text", linkText);

      await fetch(`${api}notice-edit/${id}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
        .then(async (res) => {
          if (res.ok) {
            const getData = await res.json();
            setLoading(false);
            // setSuccess(` ${currentTime()}`);
            if (getData) {
              toast.success('Successfully Saved')
            }
            setSubmitLoading(false);
          } else {
            throw new Error("Something Went Wrong");
          }
        })
        .catch((err) => {
          // setError("" + currentTime());
          toast.error('Something Went Wrong')
          setLoading(false);
          setSubmitLoading(false);
        });
    } else {
      // setError(`${currentTime()}`);
      toast.error('Important Fields Are Missing! ')
      setLoading(false);
      setSubmitLoading(false);
    }
  };

  return (
    <div className="py-8">


      <div className="mb-1 mx-auto justify-between shadow rounded bg-white p-4 w-100 lg:w-[70%]">
        <form
          ref={form}
          className="mt-8 space-y-6 text-left"
          onSubmit={(e) => submitQues(e)}
        >
          <div className="">

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className="mt-4">
                <label htmlFor="ques-name" className="relative">
                  Notice Title
                </label>

                <textarea
                  ref={textarea}
                  id="name"
                  placeholder="Name"
                  className="mt-2 mb-4 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  defaultValue={name}
                  onChange={(e) => setName(e.target.value)}
                ></textarea>
              </div>


              <div className="mt-4">
                <label htmlFor="ques-describes" className="relative">
                  Description (if)
                </label>

                <textarea
                  id="ques-describes"
                  placeholder="Describes"
                  className="mt-2 mb-4 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  defaultValue={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>

              <div className="mt-4">
                <label htmlFor="ques-describes" className="relative">
                  Link
                </label>


                <input
                  type='text'
                  placeholder="link"
                  className="rounded-none w-full px-4 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-br-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  onChange={(e) => setLink(e.target.value)}
                  defaultValue={link}
                />
              </div>

              <div className="mt-4">
                <label htmlFor="ques-describes" className="relative">
                  Link Text
                </label>


                <input
                  type='text'
                  placeholder="link Text"
                  className="rounded-none w-full px-4 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-br-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  onChange={(e) => setLinkText(e.target.value)}
                  defaultValue={linkText}
                />
              </div>

            </div>
          </div>
          <button
            type="submit"
            disabled={submitLoading ? "disabled" : null}
            className="group flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#111827] hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span className="pr-2">
              {!submitLoading || !loading ? (
                <i className="fa fa-check"></i>
              ) : (
                <i className="fas fa-circle-notch animate-spin"></i>
              )}
            </span>
            Save
          </button>
        </form>
      </div>
    </div>
  );
}
