import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import QuizRoom from "../pages/QuizRoom";
import AppWrapper from "./AppWrapper";
import '../assets/css/App.css';
import QuizList from "../pages/admin/QuizMaster/QuizList";
import QuizManage from "../pages/admin/QuizMaster/QuizManage";
import QuestionList from "../pages/admin/QuestionMaster/QuestionList";
import QuestionManage from "../pages/admin/QuestionMaster/QuestionManage";
import QuizSingleInfo from "../pages/QuizSingleInfo";
import QuizQuestions from "../pages/admin/QuizMaster/QuizQuestions";
import QuestionAdd from "../pages/admin/QuestionMaster/QuestionAdd";
import MasterLogin from "../pages/MasterLogin";
import ResultsList from "../pages/admin/QuizMaster/ResultsList";
import QuizCompleted from "./QuizRoom/QuizCompleted";
import Login from "../pages/Login";
import Profile from "../pages/Profile";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import MasterChecks from "./MasterChecks";
import QuizQuestionsPaper from "../pages/admin/QuizMaster/QuizQuestionsPaper";
import Dashboard from "../pages/admin/Dashboard/Dashboard";
import LiveExams from "../pages/LiveExams";
import NotFound from "../pages/NotFound";
import UserList from "../pages/admin/UsersMaster/UserList";
import Register from "../pages/Register";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import AboutUs from "../pages/AboutUs";
import Terms from "../pages/Terms";
import CategoryAdd from "../pages/admin/Category/CategoryAdd";
import CategoryList from "../pages/admin/Category/CategoryList";
import CategoryManage from "../pages/admin/Category/CategoryManage";
import EbookAdd from "../pages/admin/Ebook/EbookAdd";
import EbookList from "../pages/admin/Ebook/EbookList";
import EbookManage from "../pages/admin/Ebook/EbookManage";
import NoticeAdd from "../pages/admin/Notice/NoticeAdd";
import NoticeList from "../pages/admin/Notice/NoticeList";
import NoticeManage from "../pages/admin/Notice/NoticeManage";
import JobAdd from "../pages/admin/Job/JobAdd";
import JobList from "../pages/admin/Job/JobList";
import JobManage from "../pages/admin/Job/JobManage";
import { Toaster } from 'react-hot-toast';
import SubCategoryList from "../pages/admin/Category/SubCategoryList";
import SettingsIndex from "../pages/admin/Settings/SettingsIndex";
// import Jobs from "../pages/Jobs";

function App() {
  return (
    <BrowserRouter>
      <Toaster />
      <AppWrapper>
        <Routes>

          {/* PUBLIC COMPONENTS */}
          <Route path="/" element={<Home />} />
          {/* <Route path="/jobs" element={<Jobs />} /> */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/quiz-single/:id" element={<QuizSingleInfo />} />
          <Route path="/quiz-live/" element={<LiveExams />} />
          <Route path="/quiz-room/:id" element={<QuizRoom />} />
          <Route path="/quiz-completed/:id" element={<QuizCompleted />} />
          <Route path="/master/master-login" element={<MasterLogin />} />
          <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
          <Route path="/register" element={<PublicRoute><Register /></PublicRoute>} />
          <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path="*" element={<NotFound />} />

          {/* ADMIN COMPONENTS */}

          <Route path="/master/" element={<MasterChecks><Dashboard /></MasterChecks>} />
          <Route path="/master/dashboard" element={<MasterChecks><Dashboard /></MasterChecks>} />
          <Route path="/master/quiz-list" element={<MasterChecks><QuizList /></MasterChecks>} />
          <Route path="/master/quiz-manage" element={<MasterChecks><QuizManage /></MasterChecks>} />
          <Route path="/master/quiz-manage/:id" element={<MasterChecks><QuizManage /></MasterChecks>} />
          <Route path="/master/quiz-question/:id" element={<MasterChecks><QuizQuestions /></MasterChecks>} />
          <Route path="/master/quiz-question-paper/:id" element={<MasterChecks><QuizQuestionsPaper /></MasterChecks>} />
          <Route path="/master/result-list/:id" element={<MasterChecks><ResultsList /></MasterChecks>} />


          <Route path="/master/question-list" element={<MasterChecks><QuestionList /></MasterChecks>} />
          <Route path="/master/question-manage/:id" element={<MasterChecks><QuestionManage /></MasterChecks>} />
          <Route path="/master/question-add/" element={<MasterChecks><QuestionAdd /></MasterChecks>} />


          <Route path="/master/category-list" element={<MasterChecks><CategoryList /></MasterChecks>} />
          <Route path="/master/category-sub-list" element={<MasterChecks><SubCategoryList /></MasterChecks>} />
          <Route path="/master/category-manage/:id" element={<MasterChecks><CategoryManage /></MasterChecks>} />
          <Route path="/master/category-add/" element={<MasterChecks><CategoryAdd /></MasterChecks>} />


          <Route path="/master/ebook-list" element={<MasterChecks><EbookList /></MasterChecks>} />
          <Route path="/master/ebook-manage/:id" element={<MasterChecks><EbookManage /></MasterChecks>} />
          <Route path="/master/ebook-add/" element={<MasterChecks><EbookAdd /></MasterChecks>} />


          <Route path="/master/notice-list" element={<MasterChecks><NoticeList /></MasterChecks>} />
          <Route path="/master/notice-manage/:id" element={<MasterChecks><NoticeManage /></MasterChecks>} />
          <Route path="/master/notice-add/" element={<MasterChecks><NoticeAdd /></MasterChecks>} />



          <Route path="/master/job-list" element={<MasterChecks><JobList /></MasterChecks>} />
          <Route path="/master/job-manage/:id" element={<MasterChecks><JobManage /></MasterChecks>} />
          <Route path="/master/job-add/" element={<MasterChecks><JobAdd /></MasterChecks>} />


          <Route path="/master/settings/" element={<MasterChecks><SettingsIndex /></MasterChecks>} />



          <Route path="/master/user-list/" element={<MasterChecks><UserList /></MasterChecks>} />

        </Routes>
      </AppWrapper>
    </BrowserRouter>
  );
}

export default App;

