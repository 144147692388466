import { Link } from "react-router-dom";
import tConvert from "../hooks/tConvert";

export default function QuizBox({ quizID, quizTitle, quizDate, quizTime, quizTotalParticipate, quizStatus }) {


    return (
        <div className="border-indigo-400 bg-gray-700 p-6 text-white rounded-lg shadow-lg">
            <h2 className="text-xl py-2">Quiz Title: {quizTitle}</h2>
            <h3 className="text-xl py-2">ID: {quizID}</h3>
            <h3 className="text-xl py-2">Date: {quizDate}</h3>
            <h3 className="text-xl py-2">Time: {tConvert(quizTime)}</h3>
            <h3 className="text-xl py-2 mb-4">Total Participate: {quizTotalParticipate ? quizTotalParticipate : 0}</h3>
            {quizStatus == 'publish' ? <Link className="bg-[#111827] p-2 rounded-lg" to={`/quiz-single/${quizID}`}> Get Started</Link> : null}
        </div>
    );
}