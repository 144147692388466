import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import getCookie from "../../../hooks/getCookie";
import pic_for_watermarker from "../../../assets/img/pic_for_watermarker.png";
import { toast } from "react-hot-toast";
export default function QuizQuestionsPaper() {
  const { id } = useParams();

  const ques_html = useRef(null);

  const [quizQuestionsData, setQuizQuestionsData] = useState(null);
  const [quizData, setQuizData] = useState(null);
  const [Loading, setLoading] = useState(false);
  const token = getCookie("access_token");

  const api = process.env.REACT_APP_API_LINK;

  useEffect(() => {
    setLoading(true);
    if (id) {
      async function quizPreparation() {
        await fetch(`${api}questions-by-quiz/${id}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then(async (res) => {
            if (res.ok) {
              const getData = await res.json();

              if (getData.questions) {
                setQuizQuestionsData(getData.questions);
              }
              if (getData.quiz) {
                setQuizData(getData.quiz);
              }

              setLoading(false);
            } else {
              throw new Error("Something Went Wrong");
            }
          })
          .catch((err) => {
            toast.error('something went wrong')
            setLoading(false);
          });
      }
      quizPreparation();
    } else {
      setLoading(false);
    }
  }, [api, id, token]);

  function pdfPrint() {
    var a = window.open("", "", "height=1200px, width=1200px");
    a.src = "result-list";
    a.document
      .write(`<html><script src="https://cdn.tailwindcss.com"></script><style>@page { size: landscape; } table {
            font-family: Arial, Helvetica, sans-serif;
            border-collapse: collapse;
            width: 100%;
          }
          h2{
            text-align:center;
            margin: 10px 0px;
            font-weight: 400
          }
          .pdf-btn{
            display:none
          }

          .ques_head{
            border-right: double;
            border-left: double;
            border-top: double;
            border-bottom: double;
          }
          .ques_body{
            border-right: double;
            border-left: double;
            border-top: double;
            border-bottom: double;
          }
          .question-box{
            border: double
          }
          @media print{
            tr.w-full{
              width: 46% !important;
            }
          }
          
          </style>`);
    a.document.write("<body >");
    a.document.write(ques_html.current.outerHTML);
    a.document.write("</body></html>");
    a.document.close();
    a.print();
  }

  const questionHTML = () => {
    return quizQuestionsData.map((data, key) => (
      <tr key={key} className="inline-block w-full md:w-[46%] m-[2%] p-10 border-2 border-black">
        <td>
          <div className="ques-title py-4">
            <h2 className="text-xl">
              {key + 1}. {data.name}
            </h2>
          </div>
          <div className="ques-option-grid grid grid-cols-2 gap-4">
            <div className={`flex items-center gap-2 break-words`}>
              <span
                className={`border-2 px-2 cursor-pointer ${data.correct === "A" ? `border-black bg-[green]` : null
                  }`}
              >
                A
              </span>

              <p>{JSON.parse(data.options).A}</p>
            </div>

            <div className={`flex items-center gap-2 break-words`}>
              <span
                className={`border-2 px-2 cursor-pointer ${data.correct === "B" ? `border-black bg-[green]` : null
                  }`}
              >
                B
              </span>

              <p>{JSON.parse(data.options).B}</p>
            </div>

            <div className={`flex items-center gap-2 break-words`}>
              <span
                className={`border-2 px-2 cursor-pointer ${data.correct === "C" ? `border-black bg-[green]` : null
                  }`}
              >
                C
              </span>

              <p>{JSON.parse(data.options).C}</p>
            </div>

            <div className={`flex items-center gap-2 break-words`}>
              <span
                className={`border-2 px-2 cursor-pointer ${data.correct === "D" ? `border-black bg-[green]` : null
                  }`}
              >
                D
              </span>

              <p>{JSON.parse(data.options).D}</p>
            </div>
          </div>
        </td>
      </tr>
    ));
  };

  return (
    <div ref={ques_html} className="bg-white p-6 shadow-lg">
      {!Loading && quizQuestionsData ? (
        <button
          onClick={pdfPrint}
          className="pdf-btn flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-[#111827] rounded-lg shadow-md hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200"
        >
          Download PDF
        </button>
      ) : (
        <button
          disabled="disabled"
          className="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-[#111827] rounded-lg shadow-md hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200"
        >
          Loading..
        </button>
      )}
      <div className="text-center p-4 ques_head">
        {quizData ? (
          <h2 className="my-2 text-xl font-bold">{quizData.name}</h2>
        ) : null}
        {quizData ? (
          <h2 className="my-2 text-xl font-bold">Quiz ID #{quizData.id}</h2>
        ) : null}
      </div>

      <div
        className="max-w-7xl mx-auto my-0 py-10 p-6 bg-white ques_body"
        style={{
          background: `url(${pic_for_watermarker})`,
          backgroundSize: "contain",
        }}
      >
        <table className="min-w-full leading-normal">
          <thead></thead>
          <tbody>
            {quizQuestionsData ? questionHTML() : null}
          </tbody>
        </table>
      </div>
    </div>
  );
}
