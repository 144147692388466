import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";


export default function AdminDashboard({ children }) {

    const history = useNavigate();

    var dasboardMenus = [
        {
            id: 0,
            name: "Dasboard",
            icon: "fa fa-home",
            link: 'dashboard'
        },
        {
            id: 1,
            name: "Quizzes",
            icon: "fa fa-list",
            link: "quiz-list",
        },
        {
            id: 5,
            name: "Add New Quiz",
            icon: "fa fa-add",
            link: "quiz-manage"
        },
        {
            id: 156,
            name: "Questions",
            icon: "fas fa-question-circle",
            link: "question-list",
        },
        {
            id: 10,
            name: "Add New Question",
            icon: "fa fa-add",
            link: "question-add"
        },
        {
            id: 84,
            name: "Category",
            icon: "fas fa-folder-tree",
            link: "category-list",
        },
        {
            id: 85,
            name: "Sub Category",
            icon: "fas fa-folder-tree",
            link: "category-sub-list",
        },
        {
            id: 69,
            name: "Add New Category",
            icon: "fa fa-add",
            link: "category-add"
        },
        {
            id: 22,
            name: "EBook",
            icon: "fas fa-book-open",
            link: "ebook-list",
        },
        {
            id: 45,
            name: "Add New Ebook",
            icon: "fa fa-add",
            link: "ebook-add"
        },
        {
            id: 5655,
            name: "Jobs",
            icon: "fas fa-users",
            link: "job-list",
        },
        {
            id: 55,
            name: "Add New Job",
            icon: "fa fa-add",
            link: "job-add"
        },
        {
            id: 64,
            name: "Notice",
            icon: "fas fa-clipboard",
            link: "notice-list",
        },
        {
            id: 11,
            name: "Add New Notice",
            icon: "fa fa-add",
            link: "notice-add"
        },
        {
            id: 20,
            name: "Users",
            icon: "fa fa-user",
            link: "user-list"
        },
        {
            id: 25,
            name: "Settings",
            icon: "fas fa-wrench",
            link: "settings"
        },
    ];



    const [menuToggle, setMenuToggle] = useState(false);

    const [sideMenu, setSideMenu] = useState(true);


    function signOut(e) {
        document.cookie = 'access_token=; Max-Age=0;path=/;expires=0';
        document.cookie = 'access_master=; Max-Age=0;path=/;expires=0';
        history('/master/master-login');
    }

    return (

        <main className="bg-gray-200 h-screen overflow-hidden relative border-2 border-black shadow-lg">
            <div className="flex items-start justify-between">
                <div className={`h-screen lg:block shadow-lg relative w-50 md:w-60 lg:w-80 ${sideMenu ? 'hidden' : 'block'}`}>
                    <div className="bg-white h-full dark:bg-gray-700">
                        <div className="flex flex-col items-center h-full overflow-hidden text-[#003366] bg-[#ffff] rounded">
                            <Link className="flex items-center w-full px-3 mt-3" to="/master">
                                <i className="fas fa-cube text-3xl"></i>
                                <span className="ml-2 text-sm font-bold hidden md:hidden lg:block">Dashboard</span>
                            </Link>
                            <div className="w-full px-2 overflow-y-auto">
                                <div className="flex flex-col items-center w-full mt-3 border-t border-gray-700">

                                    {
                                        dasboardMenus.map((menu, index) => (

                                            !menu.submenu ?
                                                <Link key={index} className={`flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-[#111827] hover:text-white text-[0.6rem] lg:text-sm ${menuToggle === menu?.id && 'bg-[#111827] text-white'}`} to={`/master/${menu.link}`} onClick={() => { setMenuToggle(menu.id) }}>
                                                    <i className={menu.icon}></i>
                                                    <span className="ml-2 font-bold lg:block">{menu.name}</span>
                                                </Link> :
                                                <>
                                                    <Link key={index} className="flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-[#111827] hover:text-white" to={`/master/${menu.link}`} onClick={() => { setMenuToggle(menu.id) }}>
                                                        <i className={menu.icon}></i>
                                                        <span className="ml-2 text-sm font-bold hidden md:hidden lg:block">{menu.name}</span>
                                                    </Link>
                                                    <Link key={menu.submenu.id + index} className={`flex items-center w-full h-12 pl-[2rem] mt-2 rounded hover:bg-[#111827] hover:text-white ${(menuToggle !== menu?.id) ? `sr-only` : null}`} to={`/master/${menu.submenu.link}`}>
                                                        <i className={menu.submenu.icon}></i>
                                                        <span className="ml-2 text-sm font-bold hidden md:hidden lg:block">{menu.submenu.name}</span>
                                                    </Link>
                                                </>
                                        )

                                        )
                                    }
                                    <button onClick={(e) => signOut(e)} className="flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-gray-700 hover:text-gray-300 text-[0.6rem] lg:text-sm">
                                        <i className="fa-solid fa-arrow-right-from-bracket"></i>
                                        <span className="ml-2 font-bold lg:block">Log Out</span>
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full md:space-y-4">
                    <header className="w-full h-16 z-40 flex items-center justify-between">
                        <div className="block lg:hidden ml-6">
                            <button className="flex p-2 items-center rounded-full bg-white shadow text-gray-500 text-md" onClick={() => sideMenu ? setSideMenu(false) : setSideMenu(true)}>
                                <i className={sideMenu ? 'fa fa-bars' : 'fa fa-close'}></i>
                            </button>
                        </div>
                        <div className="relative z-20 flex flex-col justify-end h-full px-3 md:w-full">
                            <div className="relative p-1 flex items-center w-full space-x-4 justify-end">
                                <button className="flex p-2 items-center rounded-full bg-white shadow text-gray-400 hover:text-gray-700 text-xl">
                                    <i className="fa fa-bell"></i>
                                </button>
                                <span className="w-1 h-8 rounded-lg bg-gray-200">
                                </span>
                                <Link to="/profile" className="block relative">
                                </Link>
                            </div>
                        </div>
                    </header>

                    <div className="overflow-auto h-screen pb-24 px-4 md:px-6">

                        {children}

                    </div>
                </div>
            </div>
        </main>

    );
}