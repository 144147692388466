import { useCallback, useEffect, useReducer, useRef, useState } from "react"
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom"
import getCookie from "../../../hooks/getCookie";

export default function ResultsList() {

    const table_html = useRef(null);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    const { id } = useParams();

    const [loading, setLoading] = useState(false);


    const [resultData, setResultData] = useState(null);
    const [quizData, setQuizData] = useState(null);
    const api = process.env.REACT_APP_API_LINK;
    const token = getCookie('access_token');



    const resultByQuiz = useCallback(async () => {
        setLoading(true);
        await Promise.all([fetch(`${api}result-list-by-quiz/${id}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }),
        fetch(`${api}quiz-single/${id}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })]).then(async ([responseResult, responseQuiz]) => {

            if (responseResult.ok) {
                const resultData = await responseResult.json();
                var i = 1;
                resultData.forEach(data => {
                    Object.assign(data, { 'serial': i });
                    i++;
                });

                const newResultData = resultData;

                newResultData.sort(function (a, b) {
                    if (a.final_score === b.final_score) return b.final_score - a.final_score;
                    return b.final_score - a.final_score;
                });

                setResultData(newResultData);
                // sortBy('final')
                setLoading(false);
                forceUpdate();
            } else {
                throw new Error("This is not admin requent, Please Try again!");
            }

            if (responseQuiz.ok) {
                const getQuizData = await responseQuiz.json();
                setQuizData(getQuizData);
                setLoading(false);
            } else {
                throw new Error("This is not admin requent, Please Try again!");
            }

        }).catch((error) => {
            setLoading(false);
            // setError("" + currentTime());
            toast.error('Something Went Wrong!')
        });

    }, [api, id, token])



    useEffect(() => {
        if (id) {

            resultByQuiz();

        }
    }, [id, resultByQuiz]);


    function pdfPrint() {
        var a = window.open('', '', 'height=1200px, width=1200px');
        a.src = 'result-list';
        a.document.write(`<html><style>@page { size: landscape; } table {
            font-family: Arial, Helvetica, sans-serif;
            border-collapse: collapse;
            width: 100%;
          }
          h2{
            text-align:center;
            margin: 10px 0px;
            font-weight: 400
          }
          table td .mobile-div{
            display:none
          }
          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
        
          table tbody tr .phone_numb{
            color: transparent;
            text-shadow: 0 0 10px rgba(0,0,0,0.5);
          }

          table tr:hover {background-color: #ddd;}
          
          table th {
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: left;
            background-color: #04AA6D;
            color: white;
          }</style>`);
        a.document.write('<body >');
        a.document.write(table_html.current.outerHTML);
        a.document.write('</body></html>');
        a.document.close();
        a.print();
    }



    async function resultDelete(resultID) {
        if (resultID) {
            setLoading(true)
            await fetch(`${api}result-delete/${resultID}`, {
                method: "DELETE",
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(async (responseResult) => {

                if (responseResult.ok) {

                    const resultGetData = await responseResult.json();
                    if (resultGetData.success) {
                        // setSuccess();
                        toast.success(`${resultGetData.success}`)
                        resultByQuiz();
                    }
                    setLoading(false);

                } else {
                    throw new Error("This is not admin requent, Please Try again!");

                }

            }).catch((error) => {
                setLoading(false);
                toast.error('something went wrong')
            });
        }
    }

    function sortBy(values) {
        setLoading(true);
        setTimeout(() => {
            if (values === 'final' && resultData) {
                var i = 1;
                resultData.forEach(data => {
                    Object.assign(data, { 'serial': i });
                    i++;
                });

                const newResultData = resultData;

                newResultData.sort(function (a, b) {
                    if (a.final_score === b.final_score) return b.final_score - a.final_score;
                    return b.final_score - a.final_score;
                });

                setResultData(newResultData);

                forceUpdate();
                setLoading(false);

            } else {
                resultByQuiz();
            }
        }, 2000);
    }

    return (
        <div className="">
            {/* {error ? <AlertPopup textError={error} /> : success ? <AlertPopup textSuccess={success} /> : null} */}
            <div className="py-8">
                <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full">
                    <h2 className="text-2xl text-dark leading-tight">
                        Results List - (Quiz ID #{id})
                    </h2>
                    <div className="text-end">
                        <div className="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center">

                            {!loading ?

                                <>
                                    <select className="px-4" onChange={(e) => sortBy(e.target.value)}>
                                        <option value="0">Sort By</option>
                                        <option value="reset">Reset</option>
                                        <option value="final">Final Score</option>
                                    </select>

                                </> :
                                null
                            }

                            {!loading ?
                                <button onClick={pdfPrint} className="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-[#111827] rounded-lg shadow-md hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200">
                                    DownLoad PDF
                                </button> :
                                <button disabled="disabled" className="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-[#111827] rounded-lg shadow-md hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200">
                                    Loading..
                                </button>
                            }

                        </div>
                    </div>
                </div>
                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto" ref={table_html}>
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">

                        <div className="my-4">
                            {!loading ?
                                <>
                                    <h2 className="text-center text-xl my-4">{quizData ? quizData.name : null}</h2>
                                    <h2 className="text-center text-xl my-4">Model Test ID : #{id}</h2>
                                    <h2 className="text-center text-xl my-4">Date: {new Date().toLocaleString('en-US')}</h2>
                                </> :
                                <p className="text-center text-xl">Loading..</p>}
                        </div>

                        <table className="min-w-full leading-normal">
                            <thead className="hidden lg:contents">
                                <tr>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                        No.
                                    </th>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                        Student Name
                                    </th>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                        Roll No
                                    </th>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                        Student Phone
                                    </th>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                        Student Email
                                    </th>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                        Correct
                                    </th>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                        Wrong
                                    </th>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                        Final Score
                                    </th>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                        Data Submitted
                                    </th>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                {resultData && !loading ?
                                    resultData.map((data, key) => (
                                        <tr key={key}>
                                            <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <div className="mobile-div block lg:hidden">Serial No</div>
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {key + 1}
                                                </p>
                                            </td>
                                            <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <div className="mobile-div block lg:hidden">Student Name</div>
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {data.name}
                                                </p>
                                            </td>
                                            <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <div className="mobile-div block lg:hidden">Roll No</div>
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {data.roll_no ? data.roll_no : "No Roll"}
                                                </p>
                                            </td>
                                            <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm phone_numb">
                                                <div className="mobile-div block lg:hidden">Student Phone</div>
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {data.phone ? data.phone : 'No Phone'}
                                                </p>
                                            </td>
                                            <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <div className="mobile-div block lg:hidden">Student Email</div>
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {data.email ? data.email : 'No email'}
                                                </p>
                                            </td>
                                            <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <div className="mobile-div block lg:hidden">Correct</div>
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {data.correct ? Object.keys(JSON.parse(data.correct)).length : null}
                                                </p>
                                            </td>
                                            <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <div className="mobile-div block lg:hidden">Wrong</div>
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {data.wrong ? Object.keys(JSON.parse(data.wrong)).length : null}
                                                </p>
                                            </td>
                                            <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <div className="mobile-div block lg:hidden">Final Score</div>
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {data.final_score ? data.final_score : null}
                                                </p>
                                            </td>
                                            <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <div className="mobile-div block lg:hidden">Final Score</div>
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {data.created_at ? new Date(data.created_at).toLocaleString('en-US') : null}
                                                </p>
                                            </td>
                                            <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <div className="mobile-div block lg:hidden">Actions</div>
                                                <div className='flex'>
                                                    <button className="text-white hover:text-white bg-[#111827]  p-2 rounded-lg shadow-lg " onClick={(e) => resultDelete(data.id)} >
                                                        Delete
                                                    </button>
                                                </div>
                                            </td>

                                        </tr>
                                    ))
                                    : loading ?
                                        <tr>
                                            <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm mb-4" colSpan={12}>
                                                <h3 className='text-center font-bold text-xl'>Loading...</h3>
                                            </td>
                                        </tr> :
                                        null}


                            </tbody>
                        </table>


                        {/* <div className="px-5 bg-white py-5 flex flex-col xs:flex-row items-center xs:justify-between">
                        <div className="flex items-center">
                            <button type="button" className="w-full p-4 border text-base rounded-l-xl text-gray-600 bg-white hover:bg-gray-100">
                                <svg width="9" fill="currentColor" height="8" className="" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z">
                                    </path>
                                </svg>
                            </button>
                            <button type="button" className="w-full px-4 py-2 border-t border-b text-base text-indigo-500 bg-white hover:bg-gray-100 ">
                                1
                            </button>
                            <button type="button" className="w-full px-4 py-2 border text-base text-gray-600 bg-white hover:bg-gray-100">
                                2
                            </button>
                            <button type="button" className="w-full px-4 py-2 border-t border-b text-base text-gray-600 bg-white hover:bg-gray-100">
                                3
                            </button>
                            <button type="button" className="w-full px-4 py-2 border text-base text-gray-600 bg-white hover:bg-gray-100">
                                4
                            </button>
                            <button type="button" className="w-full p-4 border-t border-b border-r text-base  rounded-r-xl text-gray-600 bg-white hover:bg-gray-100">
                                <svg width="9" fill="currentColor" height="8" className="" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z">
                                    </path>
                                </svg>
                            </button>
                        </div>
                    </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}