import InputFields from "../../../components/FormComponents/InputFields";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import getCookie from "../../../hooks/getCookie";
import { toast } from "react-hot-toast";

export default function QuizManage() {
  const { id } = useParams();
  const history = useNavigate();

  const [loading, setLoading] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);

  // Form Fields
  const [quizName, setQuizName] = useState(null);
  const [quizDate, setQuizDate] = useState(null);
  const [quizStartTime, setQuizStartTime] = useState(null);
  const [quizEndDate, setQuizEndDate] = useState(null);
  const [quizEndTime, setQuizEndTime] = useState(null);
  const [quizTime, setQuizTime] = useState(null);
  const [quizMinusMarks, setQuizMinusMarks] = useState(null);
  const [quizPlusMarks, setQuizPlusMarks] = useState(null);
  const [quizSecurePIN, setQuizSecurePIN] = useState(null);
  const [quizStatus, setQuizStatus] = useState(null);
  const token = getCookie("access_token");

  const api = process.env.REACT_APP_API_LINK;


  const quizApiFunc = useCallback(async () => {
    await fetch(`${api}quiz-single/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        if (res.ok) {
          const getData = await res.json();

          if (
            getData.name &&
            getData.start_date &&
            getData.start_time &&
            getData.duration &&
            getData.minus_mark &&
            getData.plus_mark
          ) {
            setQuizName(getData.name);
            setQuizDate(getData.start_date);
            setQuizStartTime(getData.start_time);
            setQuizTime(getData.duration);
            setQuizMinusMarks(getData.minus_mark);
            setQuizPlusMarks(getData.plus_mark);
            setQuizStatus(getData.status);

            if (getData.end_time && getData.end_date) {
              setQuizEndTime(getData.end_time);
              setQuizEndDate(getData.end_date);
            }

            if (getData.pin) {
              setQuizSecurePIN(getData.pin);
            }


          } else {
            // setError("");
            toast.error('Something Went wrong!')

            setTimeout(() => {
              history("/master/quiz-list");
            }, 2000);
          }
          setLoading(false);
        } else {
          throw new Error("Something Went Wrong");
        }
      })
      .catch((err) => {
        // setError("");
        toast.error('Something Went Wrong')
        setLoading(false);
      });
  }, [token, api, history, id])

  useEffect(() => {
    quizApiFunc();
  }, [quizApiFunc]);

  const submitQuiz = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSubmitLoading(true);

    if (
      quizName &&
      quizDate &&
      quizStartTime &&
      quizTime &&
      quizMinusMarks &&
      quizPlusMarks
    ) {
      const formData = new FormData();
      formData.append("name", quizName);
      formData.append("start_date", quizDate);
      formData.append("start_time", quizStartTime);
      formData.append("duration", quizTime);
      formData.append("minus_mark", quizMinusMarks);
      formData.append("plus_mark", quizPlusMarks);
      if (quizStatus) {
        formData.append("status", quizStatus);
      } else {
        formData.append("status", "draft");
      }

      if (quizEndDate && quizEndTime) {
        formData.append("end_date", quizEndDate);
        formData.append("end_time", quizEndTime);
      }

      if (quizSecurePIN) {
        formData.append("pin", quizSecurePIN === "" ? false : quizSecurePIN);
      }

      await fetch(`${api}${id ? `quiz-edit/${id}` : "quiz-add"}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
        .then(async (res) => {
          if (res.ok) {
            const getData = await res.json();
            // setSuccess(getData.success + currentTime());
            toast.success('Successfully Saved!')
            setSubmitLoading(false);
            setLoading(false);
            if (!id) {
              history(`/master/quiz-question/${getData.id}`);
            }
          } else {
            throw new Error("Something Went Wrong");
          }
        })
        .catch((err) => {
          // setError(" " + currentTime());
          toast.error('Something Went Wrong')
          setLoading(false);
          setSubmitLoading(false);
        });
    } else {
      // setError(` ${currentTime()}`);
      toast.error(' Important Fields Are Missing!')
      setLoading(false);
      setSubmitLoading(false);
    }
  };

  return (
    <div className="py-8">
      <div className="mb-1 mx-auto justify-between shadow rounded bg-white p-4 w-100 lg:w-[70%]">
        {/* {error || success ? (
          <AlertPopup textError={error} textSuccess={success} />
        ) : null} */}

        <form
          className="mt-8 space-y-6 text-left"
          onSubmit={(e) => submitQuiz(e)}
        >
          <div className="">
            {id ? (
              <Link
                to={`/master/quiz-question/${id}`}
                className="group flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#111827] hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 my-6"
              >
                Add New Questions
              </Link>
            ) : null}



            <h2 className="text-black font-bold text-2xl break-words">
              Quiz Name: {quizName}
            </h2>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className="mt-4">
                <InputFields
                  title="Name"
                  id="quiz-name"
                  placeholder="Name"
                  type="text"
                  defaultValue={quizName}
                  onChange={(e) => setQuizName(e.target.value)}
                  important
                />
              </div>

              <div className="mt-4">
                <InputFields
                  title="Starting Date"
                  id="quiz-starting-date"
                  placeholder="Starting Date"
                  type="date"
                  defaultValue={quizDate}
                  onChange={(e) => setQuizDate(e.target.value)}
                  important
                />
              </div>

              <div className="mt-4">
                <InputFields
                  title="Starting Time"
                  id="quiz-starting-time"
                  placeholder="TIME"
                  type="time"
                  defaultValue={quizStartTime}
                  onChange={(e) => setQuizStartTime(e.target.value)}
                  important
                />
              </div>

              <div className="mt-4">
                <InputFields
                  title="Ending Date"
                  id="quiz-ending-date"
                  placeholder="Ending Date"
                  type="date"
                  defaultValue={quizEndDate}
                  onChange={(e) => setQuizEndDate(e.target.value)}
                />
              </div>

              <div className="mt-4">
                <InputFields
                  title="Ending Time"
                  id="quiz-ending-time"
                  placeholder="TIME"
                  type="time"
                  defaultValue={quizEndTime}
                  onChange={(e) => setQuizEndTime(e.target.value)}
                />
              </div>

              <div className="mt-4">
                <InputFields
                  title="Duration (Minutes)"
                  id="quiz-time"
                  placeholder="60"
                  type="number"
                  defaultValue={quizTime}
                  onChange={(e) => setQuizTime(e.target.value)}
                  important
                />
              </div>

              <div className="mt-4">
                <InputFields
                  title="Plus Marks"
                  id="quiz-plus-marks"
                  placeholder="Plus Marks"
                  type="number"
                  step="any"
                  defaultValue={quizPlusMarks}
                  onChange={(e) => setQuizPlusMarks(e.target.value)}
                  important
                />
              </div>

              <div className="mt-4">
                <InputFields
                  title="Minus Marks"
                  id="quiz-minus-marks"
                  placeholder="Minus Marks"
                  type="number"
                  step="any"
                  defaultValue={quizMinusMarks}
                  onChange={(e) => setQuizMinusMarks(e.target.value)}
                  important
                />
              </div>

              <div className="mt-4">
                <InputFields
                  title="Pin"
                  id="quiz-pin"
                  placeholder="Pin"
                  type="text"
                  defaultValue={quizSecurePIN}
                  onChange={(e) => setQuizSecurePIN(e.target.value)}
                  important
                />
              </div>

              <div className="mt-4">
                <label htmlFor="ques-name" className="relative">
                  Status
                </label>

                <select
                  defaultValue={quizStatus}
                  onChange={(e) => {
                    setQuizStatus(e.target.value);
                  }}
                  className="rounded-none w-full px-4 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                >
                  <option
                    value="draft"
                    selected={quizStatus === "draft" ? "selected" : null}
                  >
                    Draft
                  </option>
                  <option
                    value="publish"
                    selected={quizStatus === "publish" ? "selected" : null}
                  >
                    Publish
                  </option>
                </select>
              </div>

              <div></div>
            </div>
          </div>
          <button
            type="submit"
            disabled={submitLoading ? "disabled" : null}
            className="group flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#111827] hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span className="pr-2">
              {!submitLoading || !loading ? (
                <i className="fa fa-check"></i>
              ) : (
                <i className="fas fa-circle-notch animate-spin"></i>
              )}
            </span>
            Save
          </button>
        </form>
      </div>
    </div>
  );
}
