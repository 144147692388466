import { useEffect, useRef } from "react";
import { useState } from "react";

import pic_for_watermarker from "../../assets/img/pic_for_watermarker.png";

export default function QuizCompleteQues({ questions, result }) {

    const [quizQuestionsData, setQuizQuestionsData] = useState(false);
    const [resultD, setResultD] = useState(false);
    const ques_html = useRef(null);

    useEffect(() => {
        if (result && questions) {
            setResultD(result)
            setQuizQuestionsData(questions)
        }
    }, [questions, result]);


    function pdfPrint() {
        var a = window.open("", "", "height=1200px, width=1200px");
        a.src = "result-list";
        a.document
            .write(`<html><script src="https://cdn.tailwindcss.com"></script><style>@page { size: landscape; } table {
                font-family: Arial, Helvetica, sans-serif;
                border-collapse: collapse;
                width: 100%;
              }
              h2{
                text-align:center;
                margin: 10px 0px;
                font-weight: 400
              }
              .pdf-btn{
                display:none
              }
    
              .ques_head{
                border-right: double;
                border-left: double;
                border-top: double;
                border-bottom: double;
              }
              .ques_body{
                border-right: double;
                border-left: double;
                border-top: double;
                border-bottom: double;
              }
              .question-box{
                border: double
              }
              @media print{
                tr.w-full{
                  width: 46% !important;
                }
              }
              
              </style>`);
        a.document.write("<body >");
        a.document.write(ques_html.current.outerHTML);
        a.document.write("</body></html>");
        a.document.close();
        a.print();
    }

    const questionHTML = () => {
        return quizQuestionsData.map((data, key) => (
            <tr key={key} className="inline-block w-full md:w-[46%] m-[2%] p-10 border-2 border-black">
                <td>
                    {
                        resultD?.wrong && JSON.parse(resultD?.wrong)[`${data?.id}`] &&
                        <div className="py-2 px-4 bg-[red] text-center text-white inline-block">
                            <p className="font-bold">Wrong Answer</p>
                        </div>
                    }
                    {
                        resultD?.correct && JSON.parse(resultD?.correct)[`${data?.id}`] &&
                        <div className="py-2 px-4 bg-[green] text-center text-white inline-block">
                            <p className="font-bold">Right Answer</p>
                        </div>
                    }
                    {
                        resultD?.correct && !JSON.parse(resultD?.correct)[`${data?.id}`] && resultD?.wrong && !JSON.parse(resultD?.wrong)[`${data?.id}`] &&
                        <div className="py-2 px-4 bg-[green] text-center text-white inline-block">
                            <p className="font-bold">Not answered</p>
                        </div>
                    }

                    <div className="ques-title py-4">
                        <h2 className="text-xl">
                            {key + 1}. {data.name}
                        </h2>
                    </div>
                    <div className="ques-option-grid grid grid-cols-2 gap-4">
                        <div className={`flex items-center gap-2 break-words`}>
                            <span
                                className={`border-2 px-2 pointer-events-none ${resultD?.wrong && JSON.parse(resultD?.wrong)[`${data?.id}`] == 'A' && 'border-red bg-[red]'} ${resultD?.correct && !JSON.parse(resultD?.correct)[`${data?.id}`] && resultD?.wrong && !JSON.parse(resultD?.wrong)[`${data?.id}`] ? null : data.correct === "A" ? `border-black bg-[green]` : null
                                    }`}
                            >
                                A
                            </span>

                            <p>{JSON.parse(data.options).A}</p>
                        </div>

                        <div className={`flex items-center gap-2 break-words`}>
                            <span
                                className={`border-2 px-2 pointer-events-none ${resultD?.wrong && JSON.parse(resultD?.wrong)[`${data?.id}`] == 'B' && 'border-red bg-[red]'} ${resultD?.correct && !JSON.parse(resultD?.correct)[`${data?.id}`] && resultD?.wrong && !JSON.parse(resultD?.wrong)[`${data?.id}`] ? null : data.correct === "B" ? `border-black bg-[green]` : null
                                    }`}
                            >
                                B
                            </span>

                            <p>{JSON.parse(data.options).B}</p>
                        </div>

                        <div className={`flex items-center gap-2 break-words`}>
                            <span
                                className={`border-2 px-2 pointer-events-none ${resultD?.wrong && JSON.parse(resultD?.wrong)[`${data?.id}`] == 'C' && 'border-red bg-[red]'} ${resultD?.correct && !JSON.parse(resultD?.correct)[`${data?.id}`] && resultD?.wrong && !JSON.parse(resultD?.wrong)[`${data?.id}`] ? null : data.correct === "C" ? `border-black bg-[green]` : null
                                    }`}
                            >
                                C
                            </span>

                            <p>{JSON.parse(data.options).C}</p>
                        </div>

                        <div className={`flex items-center gap-2 break-words`}>
                            <span
                                className={`border-2 px-2 pointer-events-none ${resultD?.wrong && JSON.parse(resultD?.wrong)[`${data?.id}`] == 'D' && 'border-red bg-[red]'} ${resultD?.correct && !JSON.parse(resultD?.correct)[`${data?.id}`] && resultD?.wrong && !JSON.parse(resultD?.wrong)[`${data?.id}`] ? null : data.correct === "D" ? `border-black bg-[green]` : null
                                    }`}
                            >
                                D
                            </span>

                            <p>{JSON.parse(data.options).D}</p>
                        </div>
                    </div>
                </td>
            </tr>
        ));
    };

    return (
        <>
            <div className="text-center">

                <h1 className="my-4 text-3xl font-bold text-center">Answer Sheet</h1>
                <button onClick={pdfPrint} className="bg-[#111827] py-2 px-4 text-white font-bold rounded-xl">Download Answer Sheet</button>
            </div>
            <div
                className="max-w-7xl mx-auto my-0 py-10 p-6 bg-white ques_body"
                style={{
                    background: `url(${pic_for_watermarker})`,
                    backgroundSize: "contain",
                }}
                id="ques-ans"
                ref={ques_html}
            >
                <table className="min-w-full leading-normal">
                    <thead></thead>
                    <tbody>
                        {quizQuestionsData ? questionHTML() : null}
                    </tbody>
                </table>
            </div>
        </>
    )
}