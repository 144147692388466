import Header from "./Header";
import Footer from "./Footer";
import { Link, useLocation } from "react-router-dom";
import React from "react";
export default function AppWrapper({ children }) {
  const location = useLocation();

  return location.pathname.includes("/master") ? (
    <>
      {children}
    </>
  ) : location.pathname.includes("/privacy-policy") ||
    location.pathname.includes("/terms") ||
    location.pathname.includes("/about-us") ? (
    <>
      <Header />
      {children}
      <div>
        <div className="py-4 text-sm lg:text-lg text-center">
          <div className="flex justify-center items-center">
            <div className="ml-4 text-white hover:text-white bg-[#111827] p-2 shadow-lg">
              <Link to="/about-us">আমাদের সম্পর্কে</Link>
            </div>
            <div className="ml-4 text-white hover:text-white bg-[#111827] p-2 shadow-lg">
              <Link to="/privacy-policy">নীতিসমূহ</Link>
            </div>
          </div>
        </div>
        <div className="py-4 text-sm lg:text-lg text-center">
          <p className="text-black">
            Copyright © {new Date().getFullYear()} - CodeCareBD - All Rights Reserved. Created By{" "}
            <a
              className="text-indigo-500"
              href="https://codecarebd.net"
              target="_blank"
              rel="noreferrer"
            >
              CodeCareBD
            </a>
          </p>
        </div>
      </div>
    </>
  ) : (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
}
