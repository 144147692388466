import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { gapi } from 'gapi-script';
import AlertPopup from "../components/AlertPopup";
import currentTime from "../hooks/currentTime";

import LoginForm from "./LoginForm";

export default function Login() {

    const history = useNavigate();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const api = process.env.REACT_APP_API_LINK;

    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: process.env.REACT_APP_GOOGLE_APP_ID,
                scope: "profile email"
            });

            gapi.load('client:auth2', start);
        }

    }, []);

    const responseFacebook = (response) => {
        const user_name = response.name;
        const user_email = response.email;
        const user_ID = response.userID;
        const user_img = response.picture.data.url;


        async function FacebookApi() {
            setLoading(true);
            if (user_name && user_email && user_img && user_ID) {
                const formData = new FormData();
                formData.append("name", user_name);
                formData.append("email", user_email);
                formData.append("pp_url", user_img);
                formData.append("fb_id", user_ID);
                const googleApi = await fetch(`${api}facebook-login-api`, {
                    method: "POST",
                    body: formData
                }).then(async (res) => {
                    if (res.ok) {
                        const getData = await res.json();

                        if (getData.success) {
                            setSuccess(getData.success);
                            document.cookie = `access_token=${getData.token};path=/;`;
                            history('/');
                            window.location.reload();
                            setLoading(false);
                        } else if (getData.confirm) {
                            setSuccess(getData.confirm)
                            setLoading(false);

                        } else if (getData.error) {
                            setError(getData.error)
                            setLoading(false);

                        } else {
                            setError('Please Try again! Something Went Wrong' + currentTime());
                            setLoading(false);
                        }

                    } else {
                        throw new Error("Please Try again! Something Went Wrong");
                    }
                }).catch((err) => {
                    setError('Please Try again! Something Went Wrong' + currentTime());
                    setLoading(false)
                });
            } else {
                setLoading(false);
                setError('Please Try again! Something Went Wrong' + currentTime());
            }
        }
        FacebookApi();

    }


    const responseGoogle = (response) => {
        const user_name = response.profileObj.name;
        const user_email = response.profileObj.email;
        const user_ID = response.profileObj.googleId;
        const user_img = response.profileObj.imageUrl;

        async function GoogleApi() {
            setLoading(true);
            if (user_name && user_email && user_img && user_ID) {
                const formData = new FormData();
                formData.append("name", user_name);
                formData.append("email", user_email);
                formData.append("pp_url", user_img);
                formData.append("gg_id", user_ID);
                const googleApi = await fetch(`${api}google-login-api`, {
                    method: "POST",
                    body: formData
                }).then(async (res) => {
                    if (res.ok) {
                        const getData = await res.json();

                        if (getData.success) {

                            setSuccess(getData.success);
                            document.cookie = `access_token=${getData.token};path=/;`;
                            history('/');
                            window.location.reload();
                            setLoading(false);
                        } else if (getData.confirm) {
                            setSuccess(getData.confirm)
                            setLoading(false);
                        } else if (getData.error) {
                            setError(getData.error)
                            setLoading(false);
                        } else {
                            setError('Please Try again! Something Went Wrong' + currentTime());
                            setLoading(false);
                            history('/');
                        }

                    } else {
                        throw new Error("Please Try again! Something Went Wrong");
                    }
                }).catch((err) => {
                    setError('Please Try again! Something Went Wrong' + currentTime());
                    setLoading(false)
                });
            } else {
                setLoading(false);
                setError('Please Try again! Something Went Wrong' + currentTime());
            }
        }
        GoogleApi();

    }



    const responseGoogleFailure = (response) => {
        if (response.error == "idpiframe_initialization_failed") {
            setError('Your cookie isn\'t enabled! Please Try again!' + currentTime());
        } else {
            setError('Please Try again! Something Went Wrong' + currentTime());
        }

    }


    const responseFacebookFailure = (response) => {

        setError('Please Try again! Something Went Wrong' + currentTime());

    }

    return (
        <>
            <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <div className="text-center">
                        <i className="fas fa-user-shield text-5xl"></i>
                        <h2 className="mt-6 text-center text-3xl font-normal text-gray-900">Login Area</h2>
                    </div>

                    <div className="flex items-center justify-center gap-4">
                        {!loading && !error && !success ?
                            <>
                                <FacebookLogin
                                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                    fields="name,email,picture"
                                    scope="public_profile, email"
                                    callback={responseFacebook}
                                    onFailure={responseFacebookFailure}
                                    cssClass="px-4 py-3 bg-[#111827] w-100 text-white shadow-lg rounded"
                                    icon={<i className="fa-brands fa-facebook mr-2"></i>}
                                    disableMobileRedirect={true}

                                />

                                <GoogleLogin
                                    clientId={process.env.REACT_APP_GOOGLE_APP_ID}
                                    buttonText="Login With Google"
                                    onSuccess={responseGoogle}
                                    onFailure={responseGoogleFailure}
                                    isSignedIn={false}
                                    accessType="offline"
                                    className="px-6 py-4 bg-[#111827] text-black shadow-lg rounded-xl"
                                    theme="dark"
                                />
                            </>
                            :
                            loading ?
                                <i className="text-6xl fa-solid fa-spinner animate-spin"></i>
                                :
                                error ||
                                    success ?
                                    <AlertPopup textSuccess={success} textError={error} />
                                    :
                                    null}
                    </div>

                    <div>
                        <LoginForm />
                    </div>




                </div>
            </div>
        </>
    );
}