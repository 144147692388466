import { React, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth, User_token } from "../hooks/useAuth";

export default function PrivateRoute({ children }) {
  const history = useNavigate();
  const token = User_token();
  const Auth = useAuth();

  const api = process.env.REACT_APP_API_LINK;
  useEffect(() => {
    if (Auth) {
      async function Auth() {
        try {
          const apis = await fetch(`${api}user-details`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          if (apis.ok) {
            // setChecks(true)
          } else {
            document.cookie = "access_token=; Max-Age=0";
            document.cookie = "access_master=; Max-Age=0";
            history("/login");
          }
        } catch (err) {
          document.cookie = "access_master=; Max-Age=0";
          document.cookie = "access_token=; Max-Age=0";
        }
      }
      Auth();
    }
  }, [Auth, api, history, token]);
  // console.log(token)
  return Auth ? children : <Navigate to="/login" />;
}
