import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import getCookie from "../../../hooks/getCookie";
import tConvert from "../../../hooks/tConvert";
import currentTime from "../../../hooks/currentTime";
import DeleteBox from "../../../components/DeleteBox";
import { toast } from "react-hot-toast";

export default function QuizList() {
  const [loading, setLoading] = useState(false);
  const [deleteObj, setDeleteURL] = useState({
    url: undefined,
    trigger: false,
  });
  const api = process.env.REACT_APP_API_LINK;
  const token = getCookie("access_token");

  const [quizData, setQuizData] = useState(null);


  //Fetch Quiz
  const quizApiFunc = useCallback(async () => {
    setLoading(true);
    await fetch(`${api}quiz-all`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        if (res.ok) {
          const getData = await res.json();

          setQuizData(getData);
          setLoading(false);
        } else {
          throw new Error("Something Went Wrong");
        }
      })
      .catch((err) => {
        // setError("" + currentTime());
        toast.error('Something Went Wrong')
        setLoading(false);
      });
  }, [api, token])

  useEffect(() => {
    quizApiFunc();
  }, [quizApiFunc]);


  async function quizDuplicator(quizId) {
    setLoading(true);
    await fetch(`${api}quiz-duplicator/${quizId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        if (res.ok) {
          const getData = res.json();
          // setSuccess( + currentTime());
          if (getData) {
            toast.success(`Successfully Duplicated! ID #${quizId}`)
          }
          quizApiFunc();
        } else {
          throw new Error("Something Went Wrong");
        }
      })
      .catch((err) => {
        // setError("" + currentTime());
        toast.error(' Something Went Wrong')
        setLoading(false);
      });
  }

  function copyUrl(link) {
    navigator.clipboard.writeText(link);
    // setSuccess( + currentTime());
    toast.success(`Copied To Clipboard! ${link}`);

  }

  return (
    <div className="">
      <DeleteBox
        callback={quizApiFunc}
        apiURL={deleteObj.url}
        token={token}
        trigger={deleteObj.trigger}
      />

      {/* {error ? (
        <AlertPopup textError={error} />
      ) : success ? (
        <AlertPopup textSuccess={success} />
      ) : null} */}
      <div className="py-8">
        <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full">
          <h2 className="text-2xl text-dark leading-tight">Quizzes</h2>
          <div className="text-end">
            <form className="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center">
              <div className="relative">
                <input
                  type="text"
                  id="form-subscribe-Filter"
                  className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  placeholder="name"
                />
              </div>

              <button className="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-[#111827] rounded-lg shadow-md hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200">
                Filter
              </button>

              <Link
                to="/master/quiz-manage"
                className="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-[#111827] rounded-lg shadow-md hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200"
              >
                Add New Quiz
              </Link>
            </form>
          </div>
        </div>
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead className="hidden lg:contents">
                <tr>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                  >
                    Serial
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                  >
                    Model Test Name
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                  >
                    Model Test Url
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                  >
                    Total Questions
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                  >
                    Total Participates
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                  >
                    Pin
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                  >
                    Actions
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                  >
                    Export Result
                  </th>

                  <th
                    scope="col"
                    className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                  >
                    Export Answer Sheet
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(quizData) && !loading ? (
                  quizData.length === 0 ? (
                    <tr>
                      <td
                        className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm mb-4"
                        colSpan={12}
                      >
                        <h3 className="text-center font-bold text-xl">
                          No Quiz Created Yet!
                        </h3>
                      </td>
                    </tr>
                  ) : (
                    quizData.map((data, key) => (
                      <tr key={key}>
                        <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="block lg:hidden">Serial</div>
                          <p className="text-gray-900 whitespace-no-wrap">
                            {key + 1}
                          </p>
                        </td>
                        <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="block lg:hidden">Model Test Name</div>
                          <p className="text-gray-900 whitespace-no-wrap">
                            {data.name}
                          </p>
                        </td>
                        <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            <button
                              onClick={(e) =>
                                copyUrl(
                                  `${process.env.REACT_APP_ROOT_LINK}quiz-room/${data.id}`
                                )
                              }
                              className="text-white hover:text-white bg-[#111827]  p-2 rounded-lg shadow-lg"
                            >
                              Copy Link
                            </button>
                          </p>
                        </td>
                        <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="block lg:hidden">Date</div>
                          <p className="text-gray-900 whitespace-no-wrap">
                            {data.start_date}
                          </p>
                          <p>{tConvert(data.start_time)}</p>
                        </td>
                        <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="block lg:hidden">Total Questions</div>
                          <p className="text-gray-900 whitespace-no-wrap">
                            {data.questions
                              ? JSON.parse(data.questions).length
                              : 0}
                          </p>
                        </td>
                        <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="block lg:hidden">
                            Total Participates
                          </div>
                          <p className="text-gray-900 whitespace-no-wrap">
                            {data.total_participates
                              ? JSON.parse(data.total_participates).length
                              : 0}
                          </p>
                        </td>
                        <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="block lg:hidden">Status</div>
                          <p className="text-gray-900 whitespace-no-wrap">
                            {data.status === "publish"
                              ? "Published"
                              : data.status === "draft"
                                ? "Draft"
                                : null}
                          </p>
                        </td>
                        <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="block lg:hidden">Pin</div>
                          <p className="text-gray-900 whitespace-no-wrap">
                            {data.pin ? data.pin : null}
                          </p>
                        </td>
                        <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="block lg:hidden">Actions</div>
                          <div className="flex flex-nowrap lg:flex-wrap">
                            <Link
                              to={`/master/quiz-manage/${data.id}`}
                              className="text-white hover:text-white bg-[#111827] p-2 rounded-lg shadow-lg "
                            >
                              Edit Model Test
                            </Link>
                            <Link
                              to={`/master/quiz-question/${data.id}`}
                              className="ml-4 text-white hover:text-white bg-[#111827] mt-0 lg:mt-6 p-2 rounded-lg shadow-lg "
                            >
                              Edit/Delete Questions
                            </Link>
                          </div>
                        </td>

                        <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="block lg:hidden">Export Result</div>
                          <p className="text-gray-900 whitespace-no-wrap flex">
                            {data.total_participates ? (
                              <Link
                                to={`/master/result-list/${data.id}`}
                                className="ml-4 text-white hover:text-white bg-[#111827] p-2 rounded-lg shadow-lg "
                              >
                                See Result
                              </Link>
                            ) : (
                              "Not Yet Assigned"
                            )}
                          </p>
                        </td>

                        <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="block lg:hidden">
                            Export Answer Sheet
                          </div>
                          <p className="text-gray-900 whitespace-no-wrap flex">
                            {data.questions ? (
                              <Link
                                to={`/master/quiz-question-paper/${data.id}`}
                                className="ml-4 text-white hover:text-white bg-[#111827] p-2 rounded-lg shadow-lg"
                              >
                                See Answer
                              </Link>
                            ) : (
                              "Not Yet Assigned"
                            )}
                          </p>
                        </td>

                        <td className="flex items-center lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm mb-4">
                          <button
                            className="text-white whitespace-no-wrap text-white px-2 pt-2 m-2 rounded hover:text-white bg-rose-500"
                            title="Delete Quiz"
                            onClick={(e) =>
                              setDeleteURL({
                                url: `${api}quiz-delete/${data.id}`,
                                trigger: currentTime(),
                              })
                            }
                          >
                            <span className="lg:hidden">Delete</span>{" "}
                            <i className="fa-solid fa-xmark"></i>
                          </button>
                          <button
                            className="text-white whitespace-no-wrap text-white px-2 pt-2 m-2 rounded hover:text-white bg-indigo-500"
                            title="Duplicate Quiz"
                            onClick={(e) => quizDuplicator(data.id)}
                          >
                            <span className="lg:hidden">Duplicator</span>{" "}
                            <i className="fa-solid fa-clone"></i>
                          </button>
                        </td>
                      </tr>
                    ))
                  )
                ) : loading ? (
                  <tr>
                    <td
                      className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm mb-4"
                      colSpan={12}
                    >
                      <h3 className="text-center font-bold text-xl">
                        Loading...
                      </h3>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>

            {/* <div className="px-5 bg-white py-5 flex flex-col xs:flex-row items-center xs:justify-between">
                            <div className="flex items-center">
                                <button type="button" className="w-full p-4 border text-base rounded-l-xl text-gray-600 bg-white hover:bg-gray-100">
                                    <svg width="9" fill="currentColor" height="8" className="" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z">
                                        </path>
                                    </svg>
                                </button>
                                <button type="button" className="w-full px-4 py-2 border-t border-b text-base text-indigo-500 bg-white hover:bg-gray-100 ">
                                    1
                                </button>
                                <button type="button" className="w-full px-4 py-2 border text-base text-gray-600 bg-white hover:bg-gray-100">
                                    2
                                </button>
                                <button type="button" className="w-full px-4 py-2 border-t border-b text-base text-gray-600 bg-white hover:bg-gray-100">
                                    3
                                </button>
                                <button type="button" className="w-full px-4 py-2 border text-base text-gray-600 bg-white hover:bg-gray-100">
                                    4
                                </button>
                                <button type="button" className="w-full p-4 border-t border-b border-r text-base  rounded-r-xl text-gray-600 bg-white hover:bg-gray-100">
                                    <svg width="9" fill="currentColor" height="8" className="" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z">
                                        </path>
                                    </svg>
                                </button>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
