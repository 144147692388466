import AlertPopup from "../components/AlertPopup";
import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputFields from "../components/FormComponents/InputFields";
import currentTime from "../hooks/currentTime";

export default function LoginForm() {

    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [email, setEmail] = useState(false);
    const [password, setPassword] = useState(false);
    const [buttonIcon, setButtonIcon] = useState('fa fa-lock');
    const history = useNavigate();
    const api = process.env.REACT_APP_API_LINK;

    async function userLoginForm(e) {
        e.preventDefault();
        setButtonIcon("fas fa-circle-notch animate-spin");


        if (email && password) {
            let formData = new FormData();
            formData.append("user_email", email);
            formData.append("user_pass", password);

            await fetch(`${api}login`, {
                method: "POST",
                body: formData
            }).then(async function (response) {// first then()
                if (response.ok) {
                    const obj = await response.json();

                    if (obj && obj.token) {
                        document.cookie = `access_token=${obj.token};path=/;`;


                        setSuccess('Successfully Logged In!')
                        setButtonIcon('fa fa-lock');

                        history('/');
                    } else if (obj.error) {
                        setButtonIcon('fa fa-lock');
                        setError(`${obj.error} ${currentTime()}`)
                    } else {

                        setError("Something went Wrong!" + currentTime());
                        setButtonIcon('fa fa-lock');

                    }

                } else if (response.error) {
                    setError(`${response.error} ${currentTime()}`)
                    throw new Error(response.error);
                } else {
                    throw new Error('Something Went Wrong. Please Try Again!');
                }
            }).catch((err) => {
                setError('Something Went Wrong! Try Again' + currentTime())
                setButtonIcon('fa fa-lock');
            });
        } else {

            if (!email && email !== '') {
                setError('Email Field Missing!' + currentTime())
                setButtonIcon('fa fa-lock');

                return false;
            }

            if (!password && password !== '') {
                setError('Password Field Missing!' + currentTime())
                setButtonIcon('fa fa-lock');

                return false;
            }

        }
    }

    return (
        <div className="max-w-md w-full space-y-8">
            <AlertPopup textSuccess={success} textError={error} />
            <form className="mt-8 space-y-6 text-left" onSubmit={(e) => userLoginForm(e)}>
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                    <div>
                        <InputFields title="Email Address" onChange={(e) => setEmail(e.target.value)} type="email" id="emailAddress" placeHolder="Enter Your E-mail" />
                    </div>
                    <div>
                        <InputFields title="Password" onChange={(e) => setPassword(e.target.value)} type="password" id="password" placeholder="Enter Your Password" />
                    </div>
                </div>

                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <input
                            id="remember-me"
                            name="remember-me"
                            type="checkbox"
                            className="h-4 w-4 text-[#111827] focus:ring-indigo-500 border-gray-300 rounded"
                        />
                        <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                            Remember me
                        </label>
                    </div>

                    <div className="text-sm">

                    </div>
                </div>

                <div className="text-center">
                    <button
                        type="submit"
                        className="group w-full flex justify-center mb-6 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#111827] hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <span className="pr-2">
                            <i className={buttonIcon}></i>
                        </span>
                        Sign in
                    </button>
                    <div className="mb-4">
                        <p>Do not have any account yet? Go To</p>
                    </div>
                    <Link to="/register" className="group w-full flex justify-center mb-6 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#111827] hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Register
                    </Link>
                </div>
            </form>
        </div>
    )
}