import React from "react";

export default function ImageUpload({
  title,
  id,
  placeHolder,
  type,
  url,
  circle = null,
  imageClass,
  NoImage,
  ...rest
}) {
  const assestLink = process.env.REACT_APP_ASSESTS_LINK;
  return (
    <>
      <label htmlFor={id} className="">
        {title}
      </label>
      {url && !NoImage ? (
        <img
          src={
            url instanceof Object
              ? URL.createObjectURL(url)
              : `${assestLink}${url}`
          }
          className={
            imageClass
              ? imageClass
              : `${circle ? `rounded-full h-[50%] w-[50%]` : `h-40 w-60`}`
          }
          alt="...."
        />
      ) : null}
      <input
        id={id}
        name={id}
        type={type}
        className="rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
        placeholder={placeHolder}
        {...rest}
      />
    </>
  );
}
