import HomeHeroBG from "../assets/img/home_hero_bg.gif";
import HOMEBG from "../assets/img/home_hero_.jpg";
import HomeContent from "../components/HomeContent";

export default function PublicHome() {
  return (
    <>
      <section
        className="px-2 py-5 lg:px-8 lg:block "
        style={{
          backgroundImage: `url('${HOMEBG}')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="mt-8 max-w-6xl mx-auto py-0 p-6 text-white">
          <div className="grid grid-cols-1 items-center lg:grid-cols-2 gap-6">
            <div className="break-words my-4 p-4 text-left py-10">
              {/* <h2 className="text-4xl font-bold">CodeCareBD</h2>
            <p className="">Bank Job Preparation Platform</p>
            <div className="mt-5 text-6 font-bold leading-8">
              কোর্স প্ল্যান অনুযায়ী ব্যাংক প্রিলি ও রিটেন প্রস্তুতির জন্য{" "}
              <a href="tel:01716417634">
                <strong>01716417634</strong>
              </a>{" "}
              এই নাম্বারে বিকাশ/রকেট/নগদ দিয়ে সেন্ডমানি করে নিচের ফেসবুক লিংকে
              ইন করে সেন্ড মানির স্কিনশট ইনবক্স করে কনফার্ম করেন। ফেসবুক লিংকঃ{" "}
              <a
                href="https://www.facebook.com/codecarebd01"
                rel="noreferrer"
                target="_blank"
              >
                <strong>https://www.facebook.com/codecarebd01</strong>
              </a>
              .
            </div> */}

              <HomeContent />

              {/* <Link
              to="/login"
              className="flex w-40 text-center text-xl mt-6 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#111827] hover:bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadoow-xxl"
            >
              Get Started
            </a> */}
            </div>

            <div className="break-words my-4 flex items-center justify-center">
              <img
                src={HomeHeroBG}
                className="w-full lg:w-[70%]"
                alt="CodeCareBD"
              />
            </div>
          </div>
        </div>

        <div className="pb-14">
          <div className="mt-8 max-w-6xl mx-auto py-0 p-6 text-white">
            <h2 className="pb-12 text-5xl text-center">Our Courses</h2>
            <div className="grid grid-cols-1 items-center lg:grid-cols-2 gap-6">
              <div className="cours bg-[#1f2937] rounded-md ring-1">
                <img
                  className="rounded-t-md"
                  src="https://codecarebd.com/_next/image?url=https%3A%2F%2Fapi.codecarebd.com%2Fuploads%2Fcourse%2F1694059425-HOnlFEmPto.jpg&w=640&q=75"
                  alt=""
                />

                <div className="footer p-4">
                  <div class="  mt-8">
                    <h4 class="font-bold text-3xl text-start mb-1">
                      Web Design & Development
                    </h4>
                  </div>
                  <div class="flex items-end justify-between my-4">
                    <div class="text-start">
                      <div>Avg. Rating 0.00</div>
                      <div class="flex gap-2 text-sm"></div>
                    </div>
                    <div class="text-3xl">
                      <span>৳7999.00</span>
                    </div>
                  </div>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://codecarebd.com/courses/4f3ecc49-f1ae-4b8b-a4dc-ca234d681972"
                    class="w-full py-2 px-4 rounded-lg block"
                    style={{
                      backgroundImage:
                        "linear-gradient(to right, rgb(41, 151, 255) 50%, rgb(37, 37, 37) 51%, rgb(41, 151, 255) 100%)",
                      textAlign: "center",
                      transition: "all 0.5s ease 0s",
                      backgroundSize: "200%",
                    }}
                  >
                    কোর্সটি কিনুন
                  </a>
                </div>
              </div>
              <div className="cours bg-[#1f2937] rounded-md ring-1">
                <img
                  className="rounded-t-md"
                  src="https://codecarebd.com/_next/image?url=https%3A%2F%2Fapi.codecarebd.com%2Fuploads%2Fcourse%2F1694060529-TYEl23Uyor.jpg&w=640&q=75"
                  alt=""
                />

                <div className="footer p-4">
                  <div class="  mt-8">
                    <h4 class="font-bold text-3xl text-start mb-1">
                      Digital Marketing Course
                    </h4>
                  </div>
                  <div class="flex items-end justify-between my-4">
                    <div class="text-start">
                      <div>Avg. Rating 0.00</div>
                      <div class="flex gap-2 text-sm"></div>
                    </div>
                    <div class="text-3xl">
                      <span>৳4990.00</span>
                    </div>
                  </div>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://codecarebd.com/courses/1ea0c039-8361-4238-8262-87fa9324ceae"
                    class="w-full py-2 px-4 rounded-lg block"
                    style={{
                      backgroundImage:
                        "linear-gradient(to right, rgb(41, 151, 255) 50%, rgb(37, 37, 37) 51%, rgb(41, 151, 255) 100%)",
                      textAlign: "center",
                      transition: "all 0.5s ease 0s",
                      backgroundSize: "200%",
                    }}
                  >
                    কোর্সটি কিনুন
                  </a>
                </div>
              </div>
              <div className="cours bg-[#1f2937] rounded-md ring-1">
                <img
                  className="rounded-t-md"
                  src="https://codecarebd.com/_next/image?url=https%3A%2F%2Fapi.codecarebd.com%2Fuploads%2Fcourse%2F1694057680-KOao6oWxW9.jpg&w=640&q=75"
                  alt=""
                />

                <div className="footer p-4">
                  <div class="  mt-8">
                    <h4 class="font-bold text-3xl text-start mb-1">
                      Professional Graphic Design
                    </h4>
                  </div>
                  <div class="flex items-end justify-between my-4">
                    <div class="text-start">
                      <div>Avg. Rating 0.00</div>
                      <div class="flex gap-2 text-sm"></div>
                    </div>
                    <div class="text-3xl">
                      <span>৳5000.00</span>
                    </div>
                  </div>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://codecarebd.com/courses/769de8a4-0ba6-4a89-8789-00381423ddcf"
                    class="w-full py-2 px-4 rounded-lg block"
                    style={{
                      backgroundImage:
                        "linear-gradient(to right, rgb(41, 151, 255) 50%, rgb(37, 37, 37) 51%, rgb(41, 151, 255) 100%)",
                      textAlign: "center",
                      transition: "all 0.5s ease 0s",
                      backgroundSize: "200%",
                    }}
                  >
                    কোর্সটি কিনুন
                  </a>
                </div>
              </div>
              <div className="cours bg-[#1f2937] rounded-md ring-1">
                <img
                  className="rounded-t-md"
                  src="https://codecarebd.com/_next/image?url=https%3A%2F%2Fapi.codecarebd.com%2Fuploads%2Fcourse%2F1694059941-66V9WJETKQ.jpg&w=640&q=75"
                  alt=""
                />

                <div className="footer p-4">
                  <div class="  mt-8">
                    <h4 class="font-bold text-3xl text-start mb-1">
                      App Development With Flutter
                    </h4>
                  </div>
                  <div class="flex items-end justify-between my-4">
                    <div class="text-start">
                      <div>Avg. Rating 0.00</div>
                      <div class="flex gap-2 text-sm"></div>
                    </div>
                    <div class="text-3xl">
                      <span>৳9999.00</span>
                    </div>
                  </div>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://codecarebd.com/courses/8ca84415-e138-41ef-9fc9-3bc3c5e3a069"
                    class="w-full py-2 px-4 rounded-lg block"
                    style={{
                      backgroundImage:
                        "linear-gradient(to right, rgb(41, 151, 255) 50%, rgb(37, 37, 37) 51%, rgb(41, 151, 255) 100%)",
                      textAlign: "center",
                      transition: "all 0.5s ease 0s",
                      backgroundSize: "200%",
                    }}
                  >
                    কোর্সটি কিনুন
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
