import React from "react";
export default function InputFields({ title, id, placeHolder, type, important = null, description = null, ...rest }) {
    return (
        <>
            {title || id || important ?
                <label htmlFor={id} className="relative">
                    {title}
                    {/* {important ? <i className="fa-solid fa-circle text-[5px] absolute animate-ping"></i> : null}
                    {important ? <i className="fa-solid fa-circle text-[5px] absolute"></i> : null} */}
                </label>
                : null
            }
            
            {description ? <p className="my-4">({description})</p> : null}

            <input
                id={id}
                name={id}
                type={type}
                className="mt-2 mb-4 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder={placeHolder}
                {...rest}
            />
        </>
    );
}