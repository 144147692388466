import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import getCookie from "../../../hooks/getCookie";
import JobImages from "../../../components/JobImages";
import IsJSON from "../../../components/IsJSON";
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { toast } from "react-hot-toast";

export default function JobManage() {

  const form = useRef(null);
  const textarea = useRef(null);

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const api = process.env.REACT_APP_API_LINK;
  const token = getCookie("access_token");

  const [name, setName] = useState(null);
  // const [html, setHTML] = useState(null);
  const [editor, setEditor] = useState(EditorState.createEmpty())
  const [banner, setBanner] = useState(null);
  const [preview, setPreview] = useState(null);
  const [images, setImages] = useState(null)


  const jobAPIfunc = useCallback(async () => {
    await fetch(`${api}job-single/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        if (res.ok) {
          const getData = await res.json();

          if (getData?.success?.job) {
            setName(getData?.success?.job?.title)
            if (getData?.success?.job?.html) {
              // Htmltodraft 
              const draft = htmlToDraft(getData?.success?.job?.html);
              const contentState = ContentState?.createFromBlockArray(draft?.contentBlocks);
              const editorState = EditorState?.createWithContent(contentState);
              setEditor(editorState)
            }
            // setHTML(getData?.success?.job?.html)
            setPreview(getData?.success?.job?.banner)
            setImages(IsJSON(getData?.success?.job?.images) ? JSON.parse(getData?.success?.job?.images) : getData?.success?.job?.images)
          }
          setLoading(false);
          // setSuccess('Successfully Saved');
        } else {
          throw new Error("Something Went Wrong");
        }
      })
      .catch((err) => {
        // setError("");
        toast.error('Something Went Wrong')
        setLoading(false);
      });
  }, [api, token, id])

  useEffect(() => {
    jobAPIfunc();
  }, [jobAPIfunc]);

  const submitJob = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    setLoading(true);

    if (
      name
    ) {

      const formData = new FormData();
      formData.append("title", name);

      if (draftToHtml(convertToRaw(editor.getCurrentContent()))) {
        formData.append("html", draftToHtml(convertToRaw(editor.getCurrentContent())));
      }
      if (banner) {
        formData.append("banner", banner);
      }

      await fetch(`${api}job-edit/${id}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
        .then(async (res) => {
          if (res.ok) {
            const getData = await res.json();
            setLoading(false);
            // setSuccess(` ${currentTime()}`);
            if (getData) {
              toast.success('Successfully Saved')
            }
            setSubmitLoading(false);
          } else {
            throw new Error("Something Went Wrong");
          }
        })
        .catch((err) => {
          // setError("" + currentTime());
          toast.error('Something Went Wrong')
          setLoading(false);
          setSubmitLoading(false);
        });
    } else {
      // setError(`${currentTime()}`);
      toast.error('Important Fields Are Missing! ')
      setLoading(false);
      setSubmitLoading(false);
    }
  };

  return (
    <div className="py-8">
      {/* <AlertPopup textError={error} success={success} /> */}


      <div className="mb-1 mx-auto justify-between shadow rounded bg-white p-4 w-100 lg:w-[70%]">
        <form
          ref={form}
          className="mt-8 space-y-6 text-left"
          onSubmit={(e) => submitJob(e)}
        >

          <button
            type="submit"
            disabled={submitLoading ? "disabled" : null}
            className="group flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#111827] hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
          >
            <span className="pr-2">
              {!submitLoading || !loading ? (
                <i className="fa fa-check"></i>
              ) : (
                <i className="fas fa-circle-notch animate-spin"></i>
              )}
            </span>
            Save
          </button>
          <div className="">

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className="mt-4 col-span-2">
                <label htmlFor="ques-name" className="relative">
                  Job Title
                </label>

                <textarea
                  ref={textarea}
                  id="name"
                  placeholder="Name"
                  className="mt-2 mb-4 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  defaultValue={name}
                  onChange={(e) => setName(e.target.value)}
                ></textarea>
              </div>


              <div className="mt-4 col-span-2">
                <label htmlFor="ques-describes" className="relative">
                  Description (if)
                </label>

                {/* <textarea
                  id="ques-describes"
                  placeholder="Describes"
                  className="mt-2 mb-4 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  defaultValue={html}
                  onChange={(e) => setHTML(e.target.value)}
                ></textarea> */}

                <Editor
                  editorState={editor}
                  onEditorStateChange={setEditor}
                  wrapperClassName="border-2 p-4 rounded-lg"
                />
              </div>

              <div className="mt-4">

                <label htmlFor="ques-describes" className="relative">
                  Upload your image
                </label>

                <input type='file' placeholder="Upload your image" onChange={(e) => setBanner(e.target.files[0])} className="rounded-none w-full px-4 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-br-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" />

                {
                  preview &&

                  <a href={`${process.env.REACT_APP_ASSESTS_LINK}${preview}`} target="_blank" rel="noreferrer" title="View" ><img src={`${process.env.REACT_APP_ASSESTS_LINK}${preview}`} alt={`key`} width={350} className="mt-6" /></a>
                }

              </div>

              <div className="mt-4">

                <label htmlFor="ques-describes" className="relative">
                  Upload your extra images
                </label>

                <JobImages callbackValue={(e) => setImages(e)} defaultObj={IsJSON(images) ? JSON.parse(images) : false} />

              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
