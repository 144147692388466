import { useEffect, useState } from "react";
import currentTime from "../hooks/currentTime";
import AlertPopup from "./AlertPopup";

export default function DeleteBox({ callback, token, apiURL, trigger }) {
  const [close_btn, setCloseBtn] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  

  useEffect(()=>{
    if(trigger){
        setCloseBtn(false);
    }

  },[trigger])

  async function quizDelete() {
    setLoading(true);
    await fetch(`${apiURL}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        if (res.ok) {
          setSuccess("Successfully Deleted!" + currentTime());
          callback();
          setCloseBtn(true)
          setLoading(false)
        } else {
          throw new Error("Something Went Wrong");
        }
      })
      .catch((err) => {
        setError("Something Went Wrong" + currentTime());
        setLoading(false);
        setCloseBtn(true);
      });
  }
  return (
    <div
      className={`fixed top-0 right-0 duration-[0.5s] z-[999] bg-[#00000040] w-full h-full  ${
        close_btn ? `invisible opacity-0` : "visible opacity-100"
      }`}
    >
    {error ? <AlertPopup textError={error} /> : success ? <AlertPopup textSuccess={success} /> : null}
      <div className="absolute top-[30%] left-[20%] md:left-[30%] lg:left-[45%]">
        <div
          className={`flex p-4 mb-4 text-sm text-white bg-[#7E22CE] rounded-lg relative items-center space-x-2 relative`}
          role="alert"
        >
          <i
            className="fa-solid fa-close cursor-pointer text-2xl absolute top-4 left-4"
            onClick={() => setCloseBtn(true)}
          ></i>
          <div className="py-10 px-10">
            <h2 className="my-10 text-3xl">Are you sure ?</h2>
            <div className="flex gap-6">
              <button className="bg-[red] text-white font-bold w-full p-4 px-10 rounded-lg flex items-center gap-2 text-xl" onClick={quizDelete}>
                
                {
                    loading ? 
                    <i className="fas fa-spinner animate-spin"></i>
                    : <span>Yes</span>
                }
              </button>
              <button className="bg-[black] text-white font-bold w-full p-4 px-10 rounded-lg text-xl" onClick={(e)=> setCloseBtn(true)}>
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
