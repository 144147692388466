import { useEffect } from "react";

export default function Terms() {
  useEffect(() => {
    document.body.scrollTop =0
    document.documentElement.scrollTop =0
  }, []);
  return (
    <section className="bg-gray-200 px-2 py-5 lg:px-8 lg:block">
      <div className="mt-8 max-w-7xl mx-auto p-6">
        <h2 className="text-4xl text-center mb-6">ব্যবহারের নিয়ম</h2>
        <div>
          <p>
            <b>১) ব্যবহারের নিয়ম :</b>
          </p>

          <ul type="circle">
            <li className="my-2 text-xl">
              <b className="text-indigo-700">Register</b> এ ক্লিক করে নাম, ইমেল,
              পাসওয়ার্ড দিয়ে রেজিষ্ট্রেশন করুন অথবা{" "}
              <b className="text-indigo-700">Gmail/Facebook</b> দিয়ে লগইন করুন ।
            </li>
            <li className="my-2 text-xl">
              {" "}
              <b className="text-indigo-700">Course Section</b> থেকে আপনার
              প্রয়োজনীয় কোর্স নির্বাচন করুন ।
            </li>
            <li className="my-2 text-xl">
              <b className="text-indigo-700">Exam Section</b> থেকে আপনার Live
              Exam নির্বাচন করুন ।
            </li>
            <li className="my-2 text-xl">
              <b className="text-indigo-700">Get Started</b> বাটনে ক্লিক করে Pin
              Code দিয়ে সাবমিট করেন ।
            </li>
            <li className="my-2 text-xl">
              <b className="text-indigo-700">
                Your Name,Your Email, Your Phone
              </b>{" "}
              দিয়ে সাবমিট করে পরীক্ষা দেন ।
            </li>
            <li className="my-2 text-xl">
              সঠিক উত্তর সিলেক্ট করে{" "}
              <b className="text-indigo-700">Submit Your Answer</b> বাটন চেপে
              ফলাফল দেখুন ।
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
