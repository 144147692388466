import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useParams, Link } from "react-router-dom";
import getCookie from "../../../hooks/getCookie";
import ImageUpload from "../../../components/FormComponents/ImageUpload";
import { toast } from "react-hot-toast";

export default function QuizQuestions() {
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const form = useRef(null);
  const textarea = useRef(null);

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [quesName, setQuesName] = useState(null);
  const [quesA, setQuesA] = useState(null);
  const [quesB, setQuesB] = useState(null);
  const [quesC, setQuesC] = useState(null);
  const [quesD, setQuesD] = useState(null);
  const [quesDescribes, setQuesDescribes] = useState(null);
  const [quesCorrectAns, setQuesCorrectAns] = useState(null);
  const [quesConfirm, setQuesConfirm] = useState(false);

  const [ques_img, setQuesImg] = useState(null);

  const [quesData, setQuesData] = useState(null);

  const [close_btn, setCloseBtn] = useState(false);
  const [divHidden, setDivHidden] = useState(false);

  const api = process.env.REACT_APP_API_LINK;
  const token = getCookie("access_token");

  const quizQuestionsApi = useCallback(async () => {
    await fetch(`${api}questions-by-quiz/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        if (res.ok) {
          const getData = await res.json();

          setQuesData(getData.questions);
          setLoading(false);
        } else {
          throw new Error("Something Went Wrong");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [api, token, id])

  useEffect(() => {
    if (id) {
      setLoading(true);

      quizQuestionsApi();
    }
  }, [id, quizQuestionsApi]);

  const submitQuizQues = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSubmitLoading(true);

    if (
      id &&
      quesName &&
      quesA &&
      quesB &&
      quesC &&
      quesD &&
      quesCorrectAns &&
      quesConfirm
    ) {
      const options = {
        A: quesA,
        B: quesB,
        C: quesC,
        D: quesD,
      };

      const formData = new FormData();
      formData.append("quiz_id", id);
      formData.append("name", quesName);
      formData.append("options", JSON.stringify(options));
      formData.append("correct", quesCorrectAns);
      formData.append("ques_img", ques_img);
      if (quesDescribes) {
        formData.append("describes", quesDescribes);
      }

      await fetch(`${api}question-add`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
        .then(async (res) => {
          if (res.ok) {
            const getData = await res.json();
            setDivHidden(true);
            setCloseBtn(false);
            setSubmitLoading(false);
            setLoading(false);
            setSuccess(getData?.success);
            toast.success(`${getData?.success}`)
          } else {
            throw new Error("Something Went Wrong");
          }
        })
        .catch((err) => {
          // setError("Something Went Wrong" + currentTime());
          toast.error('Something Went Wrong')
          setSubmitLoading(false);
          setLoading(false);
        });
    } else {
      // setError(`Important Fields Are Missing! ${currentTime()}`);
      toast.error(`Important Fields Are Missing!`);
      setSubmitLoading(false);
      setLoading(false);
    }
  };

  async function deleteQues(quesID) {
    if (quesID) {
      setLoading(true);
      const formData = new FormData();
      formData.append("quesID", quesID);
      await fetch(`${api}question-delete-by-quiz/${id}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
        .then(async (res) => {
          if (res.ok) {
            const getData = await res.json();
            if (getData) {
              toast.error('successfully deleted!')
            }
            quizQuestionsApi();
            // setLoading(false);
          } else {
            throw new Error("Something Went Wrong");
          }
        })
        .catch((err) => {
          // setError("Something Went Wrong" + currentTime());
          toast.error('something wrong')
          setLoading(false);
        });
    }
  }

  function newQues(e) {
    setCloseBtn(true);
    setDivHidden(false);
    setLoading(false);
    setSubmitLoading(false);
    setQuesName(null);
    setQuesA(null);
    setQuesB(null);
    setQuesC(null);
    setQuesD(null);
    setQuesDescribes(null);
    setQuesCorrectAns(null);
    setQuesConfirm(false);
    setQuesImg(false);
    form.current.reset();
    textarea.current.value = "";
    quizQuestionsApi();
    forceUpdate();
  }

  return (
    <div className="py-8">

      <div
        className={`fixed top-[0%] z-10 right-[0%] transition bg-black h-[100%] w-[100%] opacity-70 ${close_btn || !divHidden ? `hidden` : null
          }`}
      ></div>
      <div
        className={`absolute bg-gray-100 z-20 p-10 shadow-xl rounded-lg top-[35%] right-[35%] ${close_btn || !divHidden ? `hidden` : null
          }`}
      >
        <i
          className="fa-solid fa-close cursor-pointer"
          onClick={(e) => {
            setCloseBtn(true)
            newQues(e)
          }}
        ></i>

        <h2 className="my-4 text-2xl">{success}</h2>

        <button
          className="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-[#111827] rounded-lg shadow-md hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200"
          onClick={(e) => newQues(e)}
        >
          Add Another Question
        </button>
      </div>

      <div className="mb-1 mx-auto justify-between shadow rounded bg-white p-4 w-100 lg:w-[70%]">
        <form
          className="mt-8 space-y-6 text-left"
          ref={form}
          onSubmit={(e) => submitQuizQues(e)}
        >
          <div className="">
            <h2 className="text-black font-bold text-2xl break-words">
              Question Title: {quesName}
            </h2>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div>
                <div className="mt-4">
                  <label htmlFor="ques-name" className="relative">
                    Question Title
                    
                  </label>

                  <textarea
                    ref={textarea}
                    id="ques-name"
                    placeholder="Question Title"
                    className="mt-2 mb-4 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    defaultValue={quesName}
                    onChange={(e) => setQuesName(e.target.value)}
                  ></textarea>
                </div>

                <div className="my-4 p-2 border-2">
                  <h2 className="relative">
                    Options
                    
                  </h2>
                  <div className="mt-4">
                    <div className="flex items-center">
                      <span className="px-4 py-2 bg-gray-400 rounded-bl-md rounded-none">
                        A
                      </span>
                      <input
                        type="text"
                        className="rounded-none w-full px-4 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-br-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        defaultValue={quesA}
                        onChange={(e) => setQuesA(e.target.value)}
                      />
                    </div>

                    <div className="flex items-center mt-4">
                      <span className="px-4 py-2 bg-gray-400 rounded-bl-md rounded-none">
                        B
                      </span>
                      <input
                        type="text"
                        className="rounded-none w-full px-4 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-br-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        defaultValue={quesB}
                        onChange={(e) => setQuesB(e.target.value)}
                      />
                    </div>

                    <div className="flex items-center mt-4">
                      <span className="px-4 py-2 bg-gray-400 rounded-bl-md rounded-none">
                        C
                      </span>
                      <input
                        type="text"
                        className="rounded-none w-full px-4 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-br-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        defaultValue={quesC}
                        onChange={(e) => setQuesC(e.target.value)}
                      />
                    </div>

                    <div className="flex items-center mt-4">
                      <span className="px-4 py-2 bg-gray-400 rounded-bl-md rounded-none">
                        D
                      </span>
                      <input
                        type="text"
                        className="rounded-none w-full px-4 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-br-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        defaultValue={quesD}
                        onChange={(e) => setQuesD(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <label htmlFor="ques-name" className="relative">
                    Correct Answer
                    
                  </label>

                  <select
                    defaultValue={quesCorrectAns}
                    onChange={(e) => {
                      setQuesCorrectAns(e.target.value);
                    }}
                    className="rounded-none w-full px-4 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  >
                    <option value="A">{quesA}</option>
                    <option value="B">{quesB}</option>
                    <option value="C">{quesC}</option>
                    <option value="D">{quesD}</option>
                  </select>
                </div>

                <div className="mt-4">
                  <label htmlFor="ques-describes" className="relative">
                    Question Describes (if)
                  </label>

                  <textarea
                    id="ques-describes"
                    placeholder="Question Describes"
                    className="mt-2 mb-4 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    defaultValue={quesDescribes}
                    onChange={(e) => setQuesDescribes(e.target.value)}
                  ></textarea>
                </div>

                <div className="text-black font-bold text-2xl break-words mb-6">
                  <label className="text-sm">Add Image with question (if neccessary)</label>
                  <ImageUpload
                    id="ques_img"
                    type="file"
                    NoImage={true}
                    imageClass="h-60 w-full"
                    url={ques_img ? ques_img : "no_image.png"}
                    onChange={(e) => setQuesImg(e.target.files[0])}
                  />
                </div>

                <div className="mt-4">
                  <div className="flex items-center gap-2">
                    <input
                      onClick={() =>
                        quesConfirm
                          ? setQuesConfirm(false)
                          : setQuesConfirm(true)
                      }
                      type="checkbox"
                    />
                    <span>I have checked everything</span>
                  </div>
                </div>

                <div className="mt-4">
                  <button
                    type="submit"
                    disabled={submitLoading ? "disabled" : null}
                    className="py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#111827] hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="pr-2">
                      {!submitLoading ? (
                        <i className="fa fa-check"></i>
                      ) : (
                        <i className="fas fa-circle-notch animate-spin"></i>
                      )}
                    </span>
                    Add New Question
                  </button>
                  <Link
                    to={`/master/quiz-manage/${id}`}
                    className="mx-4 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#111827] hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Back
                  </Link>
                </div>
              </div>

              <div>
                <h2 className="text-black font-bold text-2xl break-words">
                  Questions List
                </h2>

                <div className="my-4">
                  <ul>
                    {!loading ? (
                      quesData ? (
                        quesData.map((data, key) => (
                          <li
                            key={key}
                            className="border-2 px-4 py-2 bg-gray-100 my-2 flex justify-between"
                          >
                            <h3 className="text-lg">
                              {key + 1}. {data.name}{" "}
                              {data?.ques_img && "( Image )"}
                            </h3>
                            <div>
                              <Link
                                to={`/master/question-manage/${data.id}`}
                                className="text-white whitespace-no-wrap text-white px-2 pt-2 rounded hover:text-white bg-indigo-500 mr-4"
                              >
                                <i className="fa-solid fa-pen-to-square"></i>
                              </Link>
                              <button
                                className="text-white whitespace-no-wrap text-white px-2 pt-2 rounded hover:text-white bg-rose-500"
                                onClick={(e) => deleteQues(data.id)}
                              >
                                <i className="fa-solid fa-xmark"></i>
                              </button>
                            </div>
                          </li>
                        ))
                      ) : (
                        <li className="border-2 px-4 py-2 bg-gray-100 my-2">
                          <h3 className="text-lg">Not Questions Added Yet!</h3>
                        </li>
                      )
                    ) : (
                      <li className="border-2 px-4 py-2 bg-gray-100 my-2">
                        <h3 className="text-lg">Loading...</h3>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
