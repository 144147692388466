import { useCallback, useEffect, useState } from 'react';
import getCookie from '../../../hooks/getCookie';
import { toast } from 'react-hot-toast';

export default function UserList() {

    const [loading, setLoading] = useState(false);
    const api = process.env.REACT_APP_API_LINK;
    const token = getCookie('access_token');

    const [userData, setUserData] = useState(null);


    //Fetch Quiz
    const userApiFunc = useCallback(async () => {
        setLoading(true);

        await fetch(`${api}user-all`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(async (res) => {
            if (res.ok) {
                const getData = await res.json();

                setUserData(getData);
                setLoading(false);

            } else {
                throw new Error("Something Went Wrong");
            }
        }).catch((err) => {
            toast.error('something went wrong')
            setLoading(false)
        });
    }, [api, token])

    useEffect(() => {

        userApiFunc();

    }, [userApiFunc]);

    return (
        <div className="">
            <div className="py-8">
                <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full">
                    <h2 className="text-2xl text-dark leading-tight">
                        Users
                    </h2>
                    <div className="text-end">
                        <form className="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center">

                            {/*
                            <div className="relative">
                                <input type="text" id="form-subscribe-Filter" className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="name" />
                            </div>


                             <button className="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-[#111827] rounded-lg shadow-md hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200">
                                Filter
                            </button> 

                            <Link to='/master/quiz-manage' className="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-[#111827] rounded-lg shadow-md hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200">
                                Add New Quiz
                            </Link>
                            */}

                        </form>
                    </div>
                </div>
                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                        <table className="min-w-full leading-normal">
                            <thead className="hidden lg:contents">
                                <tr>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                        Serial
                                    </th>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                        Name
                                    </th>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                        Email
                                    </th>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                        Roll
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                {Array.isArray(userData) && !loading ?

                                    userData.length === 0 ?
                                        <tr>
                                            <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm mb-4" colSpan={12}>
                                                <h3 className='text-center font-bold text-xl'>No User Created Yet!</h3>
                                            </td>
                                        </tr> :

                                        userData.map((data, key) => (
                                            <tr key={key}>
                                                <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                    <div className="block lg:hidden">Serial</div>
                                                    <p className="text-gray-900 whitespace-no-wrap">
                                                        {key + 1}
                                                    </p>
                                                </td>
                                                <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                    <div className="block lg:hidden">Name</div>
                                                    <p className="text-gray-900 whitespace-no-wrap">
                                                        {data.name ? data.name : null}
                                                    </p>
                                                </td>
                                                <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                    <div className="block lg:hidden">Email</div>
                                                    <p className="text-gray-900 whitespace-no-wrap">
                                                        {data.email ? data.email : null}
                                                    </p>
                                                </td>
                                                <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                    <div className="block lg:hidden">Roll</div>
                                                    <p className="text-gray-900 whitespace-no-wrap">
                                                        {data.id ? data.id : 'No Roll Assigned Yet!'}
                                                    </p>
                                                </td>
                                                {/* <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                    <div className="block lg:hidden">Actions</div>
                                                    <div className='flex'>
                                                        <Link to={`/master/quiz-manage/${data.id}`} className="text-white hover:text-white bg-[#111827]  p-2 rounded-lg shadow-lg ">
                                                            Edit Model Test
                                                        </Link>
                                                        <Link to={`/master/quiz-question/${data.id}`} className="ml-4 text-white hover:text-white bg-[#111827]  p-2 rounded-lg shadow-lg ">
                                                            Edit/Delete Questions
                                                        </Link>
                                                    </div>
                                                </td> */}

                                            </tr>
                                        ))

                                    : loading ?
                                        <tr>
                                            <td className="flex lg:table-cell justify-between px-5 py-5 border-b border-gray-200 bg-white text-sm mb-4" colSpan={4}>
                                                <h3 className='text-center font-bold text-xl'>Loading...</h3>
                                            </td>
                                        </tr> : null}


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}