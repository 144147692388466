
import Countdown from "react-countdown";
import quizSingleImage from "../assets/img/quizSingleImage.png";
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import tConvert from "../hooks/tConvert";
import currentTime from "../hooks/currentTime";
export default function QuizSingleInfo() {
    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [quizData, setQuizData] = useState(false);
    const api = process.env.REACT_APP_API_LINK;

    useEffect(() => {
        async function quizList() {
            const quizApi = await fetch(`${api}quiz-single/${id}`, {
                method: "GET",
            }).then(async (res) => {
                if (res.ok) {
                    const getData = await res.json();
                    setQuizData(getData);
                    setLoading(false);

                } else {
                    throw new Error('Something Went Wrong!');
                    setLoading(false)
                }
            }).catch((err) => {
                setError('Something Went Wrong!' + currentTime());
                setLoading(false);
            })
        }
        quizList();
    }, [])

    return (
        <>
            <div className="bg-[#111827] py-8">
                <h1 className="text-2xl font-bold p-4 text-center text-white">{quizData.name}</h1>
            </div>
            <section className='max-w-7xl mx-auto px-2 py-10 lg:px-8 lg:block text-center'>



                <p className="my-4">{quizData.descriptions}</p>

                <p className="my-4">Total Participate: {quizData.total_participates ? JSON.parse(quizData.total_participates).length : 0}</p>

                <p className="my-4">Start Date: {quizData.start_date ? quizData.start_date : 0}</p>

                <p className="my-2 mb-10">Start Time: {quizData.start_time ? tConvert(quizData.start_time) : 0}</p>

                <Link to={`/quiz-room/${id}`} className="px-16 py-4 mt-4 font-semibold text-white bg-[#111827] shadow-md hover:bg-gray-800 rounded">
                    Get Started
                </Link>

            </section>
        </>
    );
}