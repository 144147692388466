import { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import { useNavigate, useParams } from "react-router-dom";
import currentTime from "../../hooks/currentTime";
import QuizQuestions from "../../pages/admin/QuizMaster/QuizQuestions";
import AlertPopup from "../AlertPopup";
import QuizCompleted from "./QuizCompleted";
import QuizCompleteQues from "./QuizCompleteQues";
import { useVisibilityChange } from "@uidotdev/usehooks";

export default function QuizStarted({
  user_name,
  user_phone = null,
  user_roll = null,
  user_email,
  pinCode = null,
}) {
  //Tab Chabge Disabled
  const browserVisible = useVisibilityChange();

  const [tabChangeCount, setTabChangeCount] = useState(0);

  useEffect(() => {
    if (browserVisible === false) {
      setTabChangeCount((c) => c + 1);
    }

    if (tabChangeCount > 3) {
      alert("Your exam is submited");
      submitBtn.current.click();
    }
  }, [browserVisible]);

  const quizWrapper = useRef(null);
  const { id } = useParams();
  const history = useNavigate();
  const [quizRoomTime, setQuizRoomTime] = useState(Date.now() + 6000);

  const submitBtn = useRef(null);

  const [quizQuestionsData, setQuizQuestionsData] = useState(null);
  const [quizData, setQuizData] = useState(null);
  const [resultData, setResultData] = useState(null);

  const [userSelectedObj, setUserSelectedObj] = useState([]);

  const [error, setError] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const api = process.env.REACT_APP_API_LINK;

  useEffect(() => {
    setLoading(true);
    if (id && pinCode) {
      async function quizPreparation() {
        const formData = new FormData();

        formData.append("pin", pinCode);

        const quizPinVerify = await fetch(
          `${api}questions-by-quiz-public/${id}`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then(async (res) => {
            if (res.ok) {
              const getData = await res.json();
              if (!getData.error) {
                setQuizQuestionsData(getData.questions);
                setQuizData(getData.quiz);
                if (getData.quiz.duration) {
                  setQuizRoomTime(Date.now() + getData.quiz.duration * 60000);
                }
                setLoading(false);
              } else {
                setLoading(false);
                setError(getData.error + currentTime());
              }
            } else {
              throw new Error("Something Went Wrong!");
              setLoading(false);
            }
          })
          .catch((err) => {
            setError("Something Went Wrong!" + currentTime());
            setLoading(false);
          });
      }
      quizPreparation();
    } else if (!user_name && !user_email && !user_phone) {
      setError("User Info Isn't Submitted Correctly!" + currentTime());
      setLoading(false);
      history("/");
    } else {
      setLoading(false);
    }

    window.scrollTo(0, 0);
  }, []);

  const questionTicker = (e, quesOption = null, quesId = null) => {
    e.preventDefault();

    const element = e.target;
    const parent = element.parentElement.closest(".question-box");

    if (
      !parent.hasAttribute("disabled") &&
      !element.hasAttribute("data-collected")
    ) {
      element.classList.add("border-black", "bg-[green]");
      element.setAttribute("data-collected", true);
      parent.setAttribute("disabled", "disabled");
      parent.classList.add("cursor-not-allowed", "opacity-50");

      if (quesOption && quesId) {
        quizQuestionsData.forEach((item) => {
          if (item.id == quesId && item.correct == quesOption) {
            userSelectedObj.push({
              id: quesId,
              correct: true,
              user_selected: quesOption,
            });
          } else if (item.id == quesId && item.correct != quesOption) {
            userSelectedObj.push({
              id: quesId,
              correct: false,
              user_selected: quesOption,
            });
          } else {
            return false;
          }
        });
      }
    } else {
      return false;
    }
  };

  async function submitQuiz(e) {
    e.preventDefault();
    if (
      !e.target.classList.contains("disabled") &&
      !e.target.hasAttribute("disabled")
    ) {
      if (id && user_name && user_email) {
        e.target.classList.add(
          "disabled",
          "bg-purple-400",
          "hover:bg-purple-400"
        );
        e.target.setAttribute("disabled", "disabled");

        setLoading(true);
        const formData = new FormData();
        formData.append("quiz_id", id);
        formData.append("user_name", user_name);
        formData.append("user_email", user_email);
        if (user_phone) {
          formData.append("user_phone", user_phone);
        }
        if (user_roll) {
          formData.append("roll_no", user_roll);
        }
        formData.append("user_submitted_obj", JSON.stringify(userSelectedObj));

        const submitQuiz = await fetch(`${api}result-maker`, {
          method: "POST",
          body: formData,
        })
          .then(async (res) => {
            if (res.ok) {
              const getData = await res.json();
              if (!getData.error && getData.success && getData.final_data) {
                setSuccess(getData.success);
                setResultData(getData.final_data);
                setLoading(false);
                // history(`/quiz-completed/${id}`);
              } else {
                setLoading(false);
                setError("Something Went Wrong! Quiz Doesn't submitted");
              }
            } else {
              throw new Error("Something Went Wrong!");
              setLoading(false);
            }
          })
          .catch((err) => {
            setError("Something Went Wrong!");
            setLoading(false);
          });
      }
    } else {
      return false;
    }
  }

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      setSuccess(
        "Quiz Timeout! It has been submitted automatically! Thank you"
      );
      submitBtn.current.click();
    } else {
      // Render a countdown
      return (
        <span>
          {hours} Hours: {minutes} Minutes: {seconds} Seconds
        </span>
      );
    }
  };

  return (
    <>
      {!resultData ? (
        <>
          {error ? (
            <AlertPopup textError={error} />
          ) : success ? (
            <AlertPopup textSuccess={success} />
          ) : null}

          <div className="quiz-room-time sticky top-0 bg-[#7E22CE] text-white z-10">
            <div className="my-6 text-center p-4 border-2">
              Time Taken{" "}
              <Countdown renderer={renderer} date={quizRoomTime}></Countdown>
            </div>
          </div>

          <div
            className="max-w-7xl mx-auto my-10 grid grid-cols-1 lg:grid-cols-2 gap-2"
            ref={quizWrapper}
          >
            {quizQuestionsData ? (
              <>
                {quizQuestionsData.map((data, key) => (
                  <div key={key} className="question-box border-2 p-4 mt-4">
                    <div className="ques-title">
                      <h2 className="text-xl">
                        {key + 1}. {data.name}
                      </h2>
                    </div>

                    {data?.ques_img && (
                      <div className="ques-title my-4">
                        <img
                          className="text-xl"
                          src={`${process.env.REACT_APP_ASSESTS_LINK}${data?.ques_img}`}
                          alt={data.name}
                        // onLoad={}
                        />
                      </div>
                    )}

                    <div className="ques-options mt-4">
                      <div className="ques-option-grid grid grid-cols-2 gap-4">
                        <div className={`flex items-center gap-2`}>
                          <span
                            onClick={(e) => questionTicker(e, "A", data.id)}
                            className="border-2 px-2 cursor-pointer"
                          >
                            A
                          </span>

                          <p>{JSON.parse(data.options).A}</p>
                        </div>

                        <div className={`flex items-center gap-2`}>
                          <span
                            onClick={(e) => questionTicker(e, "B", data.id)}
                            className="border-2 px-2 cursor-pointer"
                          >
                            B
                          </span>

                          <p>{JSON.parse(data.options).B}</p>
                        </div>

                        <div className={`flex items-center gap-2`}>
                          <span
                            onClick={(e) => questionTicker(e, "C", data.id)}
                            className="border-2 px-2 cursor-pointer"
                          >
                            C
                          </span>

                          <p>{JSON.parse(data.options).C}</p>
                        </div>

                        <div className={`flex items-center gap-2`}>
                          <span
                            onClick={(e) => questionTicker(e, "D", data.id)}
                            className="border-2 px-2 cursor-pointer"
                          >
                            D
                          </span>

                          <p>{JSON.parse(data.options).D}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {tabChangeCount > 0 ? (
                  <div className="text-center rounded-lg absolute text-sm sm:text-[22px] text-red-600 bg-white sm:top-5 top-2 p-2 sm:p-6 right-0 mx-3 sm:mx-0 z-[99] sm:right-5">
                    <div className="bg-red-500 sm:w-[50px] sm:h-[50px] w-[25px] h-[25px] flex items-center justify-center m-auto rounded-full sm:text-2xl text-xl text-white ">
                      !
                    </div>
                    <h1 className="text-black">
                      You have already changed browser tabs {tabChangeCount}{" "}
                      times. <br /> If you change tab more than 3 times your
                      exam will be cancelled
                    </h1>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              <h2 className="text-xl text-center font-bold">Loading...</h2>
            )}
          </div>
          <button
            className="w-[100%] mb-10 text-center px-4 py-2 font-semibold text-white bg-[#111827] rounded-lg shadow-md hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200"
            ref={submitBtn}
            onClick={(e) => submitQuiz(e)}
          >
            {Loading ? "Loading.." : "Submit Your Answer"}
          </button>
        </>
      ) : (
        <>
          <QuizCompleted result={resultData} />
          <QuizCompleteQues questions={quizQuestionsData} result={resultData} />
        </>
      )}
    </>
  );
}
