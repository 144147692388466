import { useEffect, useRef, useState } from "react";
import InputFields from "../FormComponents/InputFields";
import AlertPopup from "../AlertPopup";
import QuizStarted from "./QuizStarted";
import { Link, useNavigate, useParams } from "react-router-dom";
import getCookie from "../../hooks/getCookie";
import currentTime from "../../hooks/currentTime";

export default function QuizBeforeBegin({ pin = null }) {
  const history = useNavigate();
  const { id } = useParams();
  const [close_btn, setCloseBtn] = useState(false);
  const [divHidden, setDivHidden] = useState(true);

  const [error, setError] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [quizStart, setQuizStart] = useState(false);
  const [quizError, setQuizError] = useState(false);

  //User Div
  const [userInfoName, setUserInfoName] = useState(null);
  const [userInfoEmail, setUserInfoEmail] = useState(null);
  const [userInfoPhone, setUserInfoPhone] = useState(null);
  const [userInfoRoll, setUserInfoRoll] = useState(null);

  const api = process.env.REACT_APP_API_LINK;
  const token = getCookie("access_token");

  useEffect(() => {
    setCloseBtn(false);
    setDivHidden(true);

    async function authChecker() {
      setLoading(true);
      await fetch(`${api}user-details`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async (res) => {
          if (res.ok) {
            const getData = await res.json();
            if (getData.id && getData.name && getData.email) {
              setUserInfoEmail(getData.email);
              setUserInfoName(getData.name);
              if (getData.phone) {
                setUserInfoPhone(getData.phone);
              }
              if (getData?.id) {
                setUserInfoRoll(getData?.id);
              }

              userSubmitData(
                getData.name,
                getData.email,
                userInfoPhone,
                getData.id,
                true
              );
              // setLoading(false);
            } else {
              setLoading(false);
            }
          } else {
            throw new Error("Something Went Wrong!");
            setLoading(false);
          }
        })
        .catch((err) => {
          setSuccess("Please submit your Details!.");
          setLoading(false);
        });
    }

    authChecker();
  }, []);

  async function userSubmitData(
    userName,
    userEmail,
    userPhone = null,
    userRoll = null,
    loggedIn
  ) {
    // console.log(userInfoName)
    // e.preventDefault();

    if (id && userName && userEmail) {
      setLoading(true);
      const formData = new FormData();
      formData.append("email", userEmail);
      if (userRoll) {
        formData.append("roll", userRoll);
      }

      await fetch(`${api}user-submission-checker/${id}`, {
        method: "POST",
        body: formData,
      })
        .then(async (res) => {
          if (res.ok) {
            const getData = await res.json();
            if (getData.success) {
              localStorage.setItem(
                "user-details",
                JSON.stringify({
                  name: userName,
                  email: userEmail,
                  phone: userPhone,
                })
              );
              setSuccess(
                "Thank you for submitting. Get Started!" + currentTime()
              );
              setQuizStart(true);
              setLoading(false);
            } else if (getData.error) {
              setError(
                "You can't continue with same E-mail / Phone!" + currentTime()
              );
              setQuizError(
                "You can't continue with same E-mail / Phone!" + currentTime()
              );
              setLoading(false);
              setTimeout(() => {
                history("/");
              }, 5000);
            }
          } else {
            throw new Error("Something Went Wrong!");
          }
        })
        .catch((err) => {
          setSuccess("Please submit your Details!." + currentTime());
          setLoading(false);
        });
    } else {
      setError("Important Fields Are Missing!" + currentTime());
    }
  }

  return (
    <>
      {error ? (
        <AlertPopup textError={error} />
      ) : success ? (
        <AlertPopup textSuccess={success} />
      ) : null}

      {!quizStart ? (
        <>
          <div
            className={`fixed top-[0%] z-10 right-[0%] transition bg-black h-[100%] w-[100%] opacity-70 ${
              close_btn || !divHidden ? `hidden` : null
            }`} //</>onClick={() => setCloseBtn(true)}
          ></div>
          <div
            className={`absolute bg-gray-100 z-20 p-10 shadow-xl rounded-lg top-[25%] right-[15%] md:top-[25%] md:right-[30%] lg:top-[25%] lg:right-[40%] ${
              close_btn || !divHidden ? `hidden` : null
            }`}
          >
            {/* <i className="fa-solid fa-close cursor-pointer" onClick={() => setCloseBtn(true)}></i> */}

            {!Loading && !quizError ? (
              <>
                <h2 className="my-4 text-2xl">Participant Information</h2>
                <InputFields
                  title="Your name"
                  type="text"
                  placeholder="Your name"
                  important
                  onChange={(e) => setUserInfoName(e.target.value)}
                />

                <InputFields
                  title="Your Email"
                  type="email"
                  placeholder="Your Email"
                  important
                  onChange={(e) => setUserInfoEmail(e.target.value)}
                />

                <InputFields
                  title="Your Phone"
                  type="text"
                  placeholder="Your Phone"
                  important
                  onChange={(e) => setUserInfoPhone(e.target.value)}
                />

                <button
                  className="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-[#111827] rounded-lg shadow-md hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200"
                  onClick={(e) =>
                    userSubmitData(
                      userInfoName,
                      userInfoEmail,
                      userInfoPhone,
                      userInfoRoll
                    )
                  }
                >
                  <span className="pr-2">
                    {!Loading ? (
                      <i className="fa fa-check"></i>
                    ) : (
                      <i className="fas fa-circle-notch animate-spin"></i>
                    )}
                  </span>
                  Submit and Start
                </button>
              </>
            ) : Loading ? (
              <div className="p-20 my-10">
                <i className="text-6xl fa-solid fa-spinner animate-spin"></i>
              </div>
            ) : quizError ? (
              <div className="p-20 my-10">
                <h3 className="my-6">{quizError}</h3>
                <Link
                  to="/"
                  className="bg-[#111827] py-2 px-4 text-white font-bold rounded-lg"
                >
                  <i className="fas fa-arrow-alt-circle-left"></i> Go To Home
                </Link>
              </div>
            ) : null}
          </div>
        </>
      ) : (
        <QuizStarted
          user_name={userInfoName}
          user_email={userInfoEmail}
          user_phone={userInfoPhone}
          user_roll={userInfoRoll}
          pinCode={pin}
        />
      )}
    </>
  );
}
