import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import getCookie from "../hooks/getCookie";

export default function Profile() {
  const history = useNavigate();
  const [Loading, setLoading] = useState(false);

  const [userDetails, setUserDetails] = useState(null);

  const api = process.env.REACT_APP_API_LINK;
  const token = getCookie("access_token");

  useEffect(() => {
    async function authChecker() {
      setLoading(true);
      await fetch(`${api}user-details`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async (res) => {
          if (res.ok) {
            const getData = await res.json();
            if (getData) {
              setUserDetails(getData);
            }
            setLoading(false);
          } else {
            throw new Error("Something Went Wrong!");
          }
        })
        .catch((err) => {
          history("/login");
          setLoading(false);
        });
    }

    authChecker();
  }, []);

  function signOut(e) {
    e.preventDefault();
    document.cookie = "access_token=; Max-Age=0";
    document.cookie = "access_master=; Max-Age=0";
    history("/login");
  }
  return (
    <div className="container h-screen max-w-full">
      <div className="m-auto my-28 w-96 max-w-lg items-center justify-center overflow-hidden rounded-2xl bg-slate-200 shadow-xl">
        {!Loading && userDetails ? (
          <>
            <div className="h-24 bg-white"></div>
            <div className="-mt-[2rem] flex justify-center">
              <img
              alt="..."
                className="h-20 rounded-full"
                src={
                  userDetails.pp_url
                    ? userDetails.pp_url
                    : "https://media.istockphoto.com/vectors/male-profile-flat-blue-simple-icon-with-long-shadow-vector-id522855255?k=20&m=522855255&s=612x612&w=0&h=fLLvwEbgOmSzk1_jQ0MgDATEVcVOh_kqEe0rqi7aM5A="
                }
              />
            </div>
            <div className="mt-5 mb-1 px-3 text-center text-lg">
              {userDetails.name}
            </div>
            <div className="p-4 text-xl">
              <h2 className="p-2 text-2xl mb-2">Details</h2>
              <ul>
                <li className="flex space-x-4 p-2 border-2 mb-2">
                  <p>Roll: </p>
                  <p>
                    {userDetails.id ? userDetails.id : "No ID Assigned Yet!"}
                  </p>
                </li>
                <li className="flex space-x-4 p-2 border-2 mb-2">
                  <p>Email: </p>
                  <p>
                    {userDetails.email
                      ? userDetails.email
                      : "No Email Assigned Yet!"}
                  </p>
                </li>
                <li className="flex space-x-4 p-2 border-2 mb-2">
                  <p>Phone: </p>
                  <p>
                    {userDetails.phone
                      ? userDetails.phone
                      : "No Phone Assigned Yet!"}
                  </p>
                </li>
              </ul>

              <button
                className="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-[#111827] rounded-lg shadow-md hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200"
                onClick={(e) => signOut(e)}
              >
                Logout
              </button>
            </div>
          </>
        ) : (
          <i className="text-6xl fa-solid fa-spinner animate-spin text-center mx-40"></i>
        )}
      </div>
    </div>
  );
}
