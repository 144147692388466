import { useEffect, useState } from "react";
import ImageUploadServer from "./FormComponents/ImageUploadServer";

export default function JobImages({ defaultObj, callbackValue }) {

    const [imageObj, setImageObj] = useState(false);

    const completed = (e) => {

        if (imageObj) {
            setImageObj(prev => [...prev, {
                url: '' + e + ''
            }]);

            callbackValue([...imageObj, {
                url: '' + e + ''
            }]);
        } else {
            setImageObj([{
                url: '' + e + ''
            }]);

            callbackValue([{
                url: '' + e + ''
            }]);
        }
    }

    useEffect(() => {
        if (defaultObj) {
            setImageObj(defaultObj);
        }
    }, [defaultObj]);

    const handleRemoveCallback = (dataId) => {
        if (dataId !== undefined) {
            imageObj.forEach((element, key, object) => {
                if (key === dataId) {
                    object.splice(key, 1);
                }
            });

            setImageObj(imageObj);
        }
    };
    // console.log(JSON.stringify(imageObj))
    return (
        <div>
            <div className="">
                <ImageUploadServer
                    className="rounded-none w-full px-4 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-br-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    InputField={true}
                    IsImage={false}
                    InputChange={(e) => completed(e)}
                />
            </div>
            <div className="my-6">
                <ul itemType="1">
                    {
                        imageObj && imageObj?.map((data, key) => (

                            <li key={key} className=' border-2 p-4 my-2'>
                                <div className="flex justify-content-center gap-6">
                                    <span className="w-full">{key + 1}</span>
                                    <button onClick={() => handleRemoveCallback(key)} className="text-center text-black">X</button>
                                </div>
                                <a href={`${process.env.REACT_APP_ASSESTS_LINK}${data?.url}`} target="_blank" rel="noreferrer" title="View" ><img src={`${process.env.REACT_APP_ASSESTS_LINK}${data?.url}`} alt={`${key}`} width={350} className="mt-6" /></a>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}