import { useEffect, useState } from "react";
import currentTime from "../hooks/currentTime";
import InputFields from "./FormComponents/InputFields";
import QuizBox from "./QuizBox";

export default function QuizSearch() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [quizData, setQuizData] = useState(false);

    const [topic, setTopic] = useState(null);
    const [id, setId] = useState(null);
    const [date, setDate] = useState(null);
    const api = process.env.REACT_APP_API_LINK;

    async function quizList(e) {
        e.preventDefault();
        setLoading(true)
        const formData = new FormData();
        formData.append('topic',topic)
        formData.append('ID',id)
        formData.append('date',date)
        const quizPinVerify = await fetch(`${api}quiz-search-public`, {
            method: "POST",
            body: formData
        }).then(async (res) => {
            if (res.ok) {
                const getData = await res.json();
                if (getData.success) {
                    setQuizData(getData.quiz);
                    setLoading(false);
                } else if(getData.error) {
                    setError(getData.error)
                    setLoading(false)
                }else{
                    setLoading(false);
                }

            } else {
                throw new Error('Something Went Wrong!');
                setLoading(false)
            }
        }).catch((err) => {
            setError('Something Went Wrong!'+currentTime());
            setLoading(false);
        })
    }

    return (
        <>

            <div className="quiz max-w-sm mx-auto border-2 border-indigo-400 bg-indigo-500 p-6 shadow-2xl rounded-xl">
                <h2 className="text-2xl text-white font-bold text-center">Search</h2>
                <form className="quiz-search-form py-4 text-white">
                    <InputFields onChange={(e)=> setTopic(e.target.value)} title="Topic" placeHolder="Topic Search" type="text" />
                    <InputFields onChange={(e)=> setId(e.target.value)} title="ID" placeHolder="ID" type="text" />
                    <InputFields onChange={(e)=> setDate(e.target.value)} title="Date" placeHolder="Date" type="date" />
                    <button className="px-16 py-2 font-semibold text-white bg-purple-500 shadow-md hover:bg-[#111827] rounded-xl" onClick={(e) =>quizList(e)}>
                        Search
                    </button>
                </form>
            </div>

            {quizData ?
                <div className="mt-8 bg-gray-200 p-6 max-w-7xl mx-auto">
                    <h2 className="my-4 text-2xl font-bold">Search Result</h2>
                    {!loading ?
                        <div className="grid grid-cols-2 p-6 gap-6">
                            {quizData ?
                                quizData.map((data, key) => (
                                    <QuizBox key={key} quizID={data.id} quizTitle={data.name} quizDate={data.start_date} quizTime={data.start_time} quizTotalParticipate={data.total_participates ? JSON.parse(data.total_participates).length : null} quizStatus={data.status} />
                                )) :

                                null

                            }
                        </div>
                        :
                        <span className="animate-spin text-6xl my-6 fa-solid fa-spinner"></span>}
                </div> :
                null
            }
        </>
    );
}