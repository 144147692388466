import { React, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import getCookie from "../hooks/getCookie";
import { CheckMaster } from "../hooks/useAuth";
import AdminDashboard from "../pages/admin/AdminDashboard";
export default function MasterChecks({ children }) {
  const history = useNavigate();
  const token = getCookie("access_master");
  const checks = CheckMaster();
  const api = process.env.REACT_APP_API_LINK;

  useEffect(() => {
    if (checks) {
      async function Auth() {
        try {
          const apis = await fetch(`${api}master`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          if (apis.ok) {
          } else {
            history("/not-found");
            document.cookie = "ccd_master=; Max-Age=0";
            document.cookie = "access_token=; Max-Age=0";
          }
        } catch (err) {
          document.cookie = "ccd_master=; Max-Age=0";
          document.cookie = "access_token=; Max-Age=0";
          history("/not-found");
        }
      }
      Auth();
    }
  }, [checks, token, api, history]);

  return checks ? (
    <AdminDashboard>{children}</AdminDashboard>
  ) : (
    <Navigate to="/not-found" />
  );
}
