import { useCallback, useEffect, useRef, useState } from "react";
import getCookie from "../../../hooks/getCookie";
import { toast } from "react-hot-toast";

import htmlToDraft from "html-to-draftjs";
import IsJSON from "../../../components/IsJSON";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

export default function SettingsIndex() {

    const form = useRef(null);

    const [editor, setEditor] = useState(EditorState.createEmpty());
    const [buttonTitle, setButtonTitle] = useState(null);
    const [buttonLink, setButtonLink] = useState(null);
    const [advertisement_banner_1, set_advertisement_banner_1] = useState(null);
    const [advertisement_banner_2, set_advertisement_banner_2] = useState(null);
    const [advertisement_banner_3, set_advertisement_banner_3] = useState(null);
    const [advertisement_banner_4, set_advertisement_banner_4] = useState(null);


    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    const api = process.env.REACT_APP_API_LINK;
    const token = getCookie("access_token");

    const [title, setTitle] = useState(null);
    // const [file, setFile] = useState(null);
    // const [preImg, setPreImg] = useState(null)


    const settingsApi = useCallback(async () => {
        await fetch(`${api}frontend`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        }).then(async (res) => {
            if (res.ok) {
                const getData = await res.json();

                if (getData?.success?.frontend) {
                    setTitle(getData?.success?.frontend?.title);
                    setButtonLink(IsJSON(getData?.success?.frontend?.notice_button) && JSON.parse(getData?.success?.frontend?.notice_button)?.buttonLink)
                    setButtonTitle(IsJSON(getData?.success?.frontend?.notice_button) && JSON.parse(getData?.success?.frontend?.notice_button)?.buttonTitle)
                    set_advertisement_banner_1(getData?.success?.frontend?.advertisement_banner_1)
                    set_advertisement_banner_2(getData?.success?.frontend?.advertisement_banner_2)
                    set_advertisement_banner_3(getData?.success?.frontend?.advertisement_banner_3)
                    set_advertisement_banner_4(getData?.success?.frontend?.advertisement_banner_4)
                    console.log(getData?.success?.frontend?.advertisement_banner_1)
                    // setPreImg(getData?.success?.frontend?.image);

                    if (getData?.success?.frontend?.notice) {
                        const draft = htmlToDraft(getData?.success?.frontend?.notice);

                        const contentState = ContentState?.createFromBlockArray(draft?.contentBlocks);
                        const editorState = EditorState?.createWithContent(contentState);

                        setEditor(editorState)

                    }
                }
                setLoading(false);
            } else {
                throw new Error("Something Went Wrong");
            }
        })
            .catch((err) => {
                toast.error('Something Went Wrong')
                setLoading(false);
            });
    }, [api, token])

    useEffect(() => {
        settingsApi();
    }, [settingsApi]);

    const submitSettings = async (e) => {
        e.preventDefault();
        setSubmitLoading(true);
        setLoading(true);


        const formData = new FormData();
        formData.append("title", title);
        formData.append("advertisement_banner_1", advertisement_banner_1);
        formData.append("advertisement_banner_2", advertisement_banner_2);
        formData.append("advertisement_banner_3", advertisement_banner_3);
        formData.append("advertisement_banner_4", advertisement_banner_4);
        formData.append("notice", draftToHtml(convertToRaw(editor.getCurrentContent())));
        formData.append("notice_button", JSON.stringify({
            buttonLink: buttonLink,
            buttonTitle: buttonTitle,
        }));

        await fetch(`${api}frontend-save`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        })
            .then(async (res) => {
                if (res.ok) {
                    const getData = await res.json();
                    if (getData) {
                        toast.success('Successfully Saved ')
                    }
                    setLoading(false);
                    // setSuccess(`${currentTime()}`);
                    setSubmitLoading(false);
                } else {
                    throw new Error("Something Went Wrong");
                }
            })
            .catch((err) => {
                // setError("" + currentTime());
                toast.error('Something Went Wrong')
                setLoading(false);
                setSubmitLoading(false);
            });
    };

    return (
        <div className="py-8">


            <div className="mb-1 mx-auto justify-between shadow rounded bg-white p-4 w-100 lg:w-[70%]">
                <form
                    ref={form}
                    className="mt-8 space-y-6 text-left"
                    onSubmit={(e) => submitSettings(e)}
                >
                    <div className="">
                        {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4"> */}


                        <>
                            <div className={`mt-4 col-span-2`}>
                                <label htmlFor="ques-describes" className="relative">
                                    Notice
                                </label>

                                <Editor
                                    editorState={editor}
                                    onEditorStateChange={setEditor}
                                    wrapperClassName="border-2 p-4 rounded-lg"
                                />
                            </div>

                            <div className="mt-4">
                                <label htmlFor="ques-name" className="relative">
                                    Button Title
                                </label>

                                <input
                                    type="text"
                                    placeholder="Title"
                                    className="mt-2 mb-4 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    defaultValue={buttonTitle}
                                    onChange={(e) => setButtonTitle(e.target.value)}
                                />
                            </div>

                            <div className="mt-4">
                                <label htmlFor="ques-name" className="relative">
                                    Button Link
                                </label>

                                <input
                                    type="text"
                                    placeholder="Link"
                                    className="mt-2 mb-4 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    defaultValue={buttonLink}
                                    onChange={(e) => setButtonLink(e.target.value)}
                                />
                            </div>


                            <div className="mt-4">
                                <label htmlFor="ques-name" className="relative">
                                    Advertisement Banner 1
                                </label>

                                {
                                    advertisement_banner_1 && typeof advertisement_banner_1 === 'object' &&
                                    <img src={URL.createObjectURL(advertisement_banner_1)} alt="Advertisement Banner 1" />
                                }

                                {
                                    typeof advertisement_banner_1 === 'string' &&
                                    <img src={`${process.env.REACT_APP_ASSESTS_LINK}${advertisement_banner_1}`} alt="Advertisement Banner 1" />
                                }

                                <input
                                    type="file"
                                    className="mt-2 mb-4 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    defaultValue={advertisement_banner_1}
                                    onChange={(e) => set_advertisement_banner_1(e.target.files[0])}
                                />
                            </div>

                            <div className="mt-4">
                                <label htmlFor="ques-name" className="relative">
                                    Advertisement Banner 2
                                </label>

                                {
                                    advertisement_banner_2 && typeof advertisement_banner_2 === 'object' &&
                                    <img src={URL.createObjectURL(advertisement_banner_2)} alt="Advertisement Banner 2" />
                                }

                                {
                                    typeof advertisement_banner_2 === 'string' &&
                                    <img src={`${process.env.REACT_APP_ASSESTS_LINK}${advertisement_banner_2}`} alt="Advertisement Banner 2" />
                                }

                                <input
                                    type="file"
                                    className="mt-2 mb-4 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    defaultValue={advertisement_banner_2}
                                    onChange={(e) => set_advertisement_banner_2(e.target.files[0])}
                                />
                            </div>

                            <div className="mt-4">
                                <label htmlFor="ques-name" className="relative">
                                    Advertisement Banner 3
                                </label>

                                {
                                    advertisement_banner_3 && typeof advertisement_banner_3 === 'object' &&
                                    <img src={URL.createObjectURL(advertisement_banner_3)} alt="Advertisement Banner 3" />
                                }

                                {
                                    typeof advertisement_banner_3 === 'string' &&
                                    <img src={`${process.env.REACT_APP_ASSESTS_LINK}${advertisement_banner_3}`} alt="Advertisement Banner 3" />
                                }

                                <input
                                    type="file"
                                    className="mt-2 mb-4 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    defaultValue={advertisement_banner_3}
                                    onChange={(e) => set_advertisement_banner_3(e.target.files[0])}
                                />
                            </div>

                            <div className="mt-4">
                                <label htmlFor="ques-name" className="relative">
                                    Advertisement Banner 4
                                </label>

                                {
                                    advertisement_banner_4 && typeof advertisement_banner_4 === 'object' &&
                                    <img src={URL.createObjectURL(advertisement_banner_4)} alt="Advertisement Banner 4" />
                                }

                                {
                                    typeof advertisement_banner_4 === 'string' &&
                                    <img src={`${process.env.REACT_APP_ASSESTS_LINK}${advertisement_banner_4}`} alt="Advertisement Banner 4" />
                                }

                                <input
                                    type="file"
                                    className="mt-2 mb-4 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    defaultValue={advertisement_banner_4}
                                    onChange={(e) => set_advertisement_banner_4(e.target.files[0])}
                                />
                            </div>


                        </>
                        {/* </div> */}
                    </div>
                    <button
                        type="submit"
                        disabled={submitLoading ? "disabled" : null}
                        className="group flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#111827] hover:bg-[#111827] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <span className="pr-2">
                            {!submitLoading || !loading ? (
                                <i className="fa fa-check"></i>
                            ) : (
                                <i className="fas fa-circle-notch animate-spin"></i>
                            )}
                        </span>
                        Save
                    </button>
                </form>
            </div>
        </div>
    );
}
